import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Cancel from '../../assets/noteCancel.svg';
import Modified from '../../assets/noteModified.svg';
import Medication from '../../assets/pill.svg';
import Profile from '../../assets/profileOn.svg';
import Active from '../../assets/agt.svg';
import { useSelector } from 'react-redux';

function NotifySide (props) {
    const { userInfo } = useSelector((state) => state.userInfo);
    // eslint-disable-next-line no-console

    const New = userInfo.notification.slice(0, 3);

    const Previous = userInfo.notification.slice(3, 6);

    const ref = useRef(null);

    useEffect(() => {
        function handleClickOutside (event) {
            if (ref.current && !ref.current.contains(event.target)) {
                props.setNotify(false);
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref]);
    return (
        <div className='transition max-[1600px]:w-[calc(100%-115px)] max-[1600px]:h-[calc(100%-50px)] w-[calc(100%-143px)] h-[calc(100%-75px)] absolute bottom-0 right-0 bg-[rgba(0,0,0,0.2)] duration-[2s] overflow-y-auto mt-[75px] z-[101]'>
            <div className='w-[365px] max-[1600px]:w-[300px] h-full absolute top-0 right-0 bg-[#F9FAFD] max-[1600px]:pl-[15px] max-[1600px]:pt-[20px] pl-[20px] pt-[30px] flex justify-between flex-col' ref={ref}>
                <div className={'overflow-auto pr-[20px]'}>
                    <div className='flex justify-between items-center mb-[25px] max-[1600px]:mb-[15px]'>
                        <div className='font-["Montserrat"] font-[600] max-[1600px]:text-[18px] text-[24px] text-[#282828] flex items-center leading-[20px]'>Notifications
                            {/* <img src={Filter} className={'w-[20px] h-[20px] ml-[15px]'} /> */}
                        </div>
                        <Link to={'notification'}>
                            <div className='font-["Montserrat"] font-[600] max-[1600px]:text-[12px] text-[14px] text-primary' onClick={() => props.setNotify(false)}>
                            View all
                            </div>
                        </Link>
                    </div>
                    <div>
                        <div className='font-["Montserrat"] font-[600] max-[1600px]:text-[13px] text-[14px] text-[#818181] mb-[15px]'>New</div>
                        { console.log('new notifications: ', New)}
                        {New.map((data, i) => {
                            return (
                                <div className="bg-[#FFFFFF] shadow-[0px_2px_6px_0px_rgba(101,106,116,0.25)] max-[1600px]:py-[15px] max-[1600px]:px-[10px] py-[20px] px-[15px] rounded-[8px] mb-[8px]" key={i}>
                                    {console.log('data type: ', data)}
                                    {data.type === 'refill' &&
                                    <div className={'flex justify-center items-start'}>
                                        <img src={Medication} className={'mr-[15px]'}/>
                                        <div className='flex flex-col justify-between'>
                                            <div className={'font-["Montserrat"] font-[400] max-[1600px]:text-[12px] text-[13px] text-[#282828] leading-[17px]'}>
                                                <span className={'font-[500] text-primary'}>{data.patient_name}</span> sent a medication refill request for <span className={'font-[500] text-[#000000]'}>{data.medicine}</span>.
                                            </div>
                                            {/* <div className={'font-["Montserrat"] font-[600] max-[1600px]:text-[12px] text-[14px] text-primary leading-[15px] flex items-center'}>
                                                View details
                                                <img src={Active} className={'ml-[10px]'}></img>
                                            </div> */}
                                        </div>
                                    </div>}
                                    {data.type === 'profile' &&
                                    <div className={'flex justify-center items-start'}>
                                        <img src={Profile} className={'mr-[15px]'}/>
                                        <div className='flex flex-col justify-between'>
                                            <div className={'font-["Montserrat"] font-[400] max-[1600px]:text-[12px] text-[13px] text-[#282828] leading-[17px]'}>
                                                <span className={'font-[500] text-primary'}>{data.patient_name}</span> sent a profile modification request <span className={'font-[500] text-[#000000]'}>{data.medicine}</span>.
                                            </div>
                                            {/* <div className={'font-["Montserrat"] font-[600] max-[1600px]:text-[12px] text-[14px] text-primary leading-[15px] flex items-center'}>
                                                View details
                                                <img src={Active} className={'ml-[10px]'}></img>
                                            </div> */}
                                        </div>
                                    </div>}
                                    {data.type === 'new' &&
                                    <div className={'flex justify-center items-start'}>
                                        <img src={Modified} className={'mr-[15px]'}/>
                                        <div className='flex flex-col justify-between'>
                                            <div className={'font-["Montserrat"] font-[400] max-[1600px]:text-[12px] text-[13px] text-[#282828] leading-[17px]'}>
                                                <span className={'font-[500] text-primary'}>{data.patient_name}</span> sent a request for an appointment with <span className={'font-[500] text-[#000000]'}>{data.doctor_name}</span>.
                                            </div>
                                            <div className={'font-["Montserrat"] font-[400] max-[1600px]:text-[11px] text-[12px] text-[#818181] leading-[15px] max-[1600px]:mt-[8px] mt-[10px] mb-[15px] max-[1600px]:mb-[10px]'}>
                                                {data.modify}
                                            </div>
                                            {/* <div className={'font-["Montserrat"] font-[600] max-[1600px]:text-[12px] text-[14px] text-primary leading-[15px] flex items-center'}>
                                                View details
                                                <img src={Active} className={'ml-[10px]'}></img>
                                            </div> */}
                                        </div>
                                    </div>}
                                    {data.type === 'cancel' &&
                                    <div className={'flex justify-center items-start'}>
                                        <img src={Cancel} className={'mr-[15px]'}/>
                                        <div className='flex flex-col justify-between'>
                                            <div className={'font-["Montserrat"] font-[400] max-[1600px]:text-[12px] text-[13px] text-[#282828] leading-[17px]'}>
                                                <span className={'font-[500] text-primary'}>{data.patient_name}</span> sent a request to cancel the appointment for <span className={'font-[500] text-[#000000]'}>{data.date}</span>.
                                            </div>
                                            <div className={'font-["Montserrat"] font-[400] max-[1600px]:text-[11px] text-[12px] text-[#818181] leading-[15px] max-[1600px]:mt-[8px] mt-[10px] mb-[15px] max-[1600px]:mb-[10px]'}>
                                                {data.time}
                                            </div>
                                            {/* <div className={'font-["Montserrat"] font-[600] max-[1600px]:text-[12px] text-[14px] text-primary leading-[15px] flex items-center'}>
                                                View details
                                                <img src={Active} className={'ml-[10px]'}></img>
                                            </div> */}
                                        </div>
                                    </div>}
                                    {data.type === 'chat' &&
                                    <div className={'flex justify-center items-start'}>
                                        <div className='min-w-[35px] h-[35px] mr-[15px] font-[600] font-["Montserrat"] text-[14px] leading-[17px] text-[#FFFFFF] bg-primary rounded-full flex justify-center items-center'>
                                            {data.name?.split(' ').map((name) => name[0])}
                                        </div>
                                        <div className='flex flex-col justify-between'>
                                            <div className={'font-["Montserrat"] font-[600] max-[1600px]:text-[13px] text-[14px] text-primary leading-[17px]'}>
                                            You have new message from {data.name}.
                                            </div>
                                            <div className={'font-["Montserrat"] font-[400] max-[1600px]:text-[11px] text-[12px] text-[#818181] leading-[15px] max-[1600px]:mt-[8px] mt-[10px] mb-[15px] max-[1600px]:mb-[10px]'}>
                                                {data.time}
                                            </div>
                                            <Link to='/dashboard/chat'><div className={'font-["Montserrat"] font-[600] text-[14px] text-primary leading-[15px] flex items-center'} onClick={() => props.setNotify(false)}>
                                                Go to chat
                                                <img src={Active} className={'ml-[10px]'}></img>
                                            </div></Link>
                                        </div>
                                    </div>}
                                    {data.type === 'modified' &&
                                    <div className={'flex justify-center items-start'}>
                                        <img src={Modified} className={'mr-[15px]'}/>
                                        <div className='flex flex-col justify-between'>
                                            <div className={'font-["Montserrat"] font-[400] max-[1600px]:text-[12px] text-[13px] text-[#282828] leading-[17px]'}>
                                                An appointment for <span className={'font-[500] text-primary'}>{data.patient_name}</span> with <span className={'font-[500] text-[#000000]'}>{data.doctor_name}</span> was modified.
                                            </div>
                                            <div className={'font-["Montserrat"] font-[400] max-[1600px]:text-[11px] text-[12px] text-[#818181] leading-[15px] max-[1600px]:mt-[8px] mt-[10px] mb-[15px] max-[1600px]:mb-[10px]'}>
                                                {data.time}
                                            </div>
                                            {/* <div className={'font-["Montserrat"] font-[600] max-[1600px]:text-[12px] text-[14px] text-primary leading-[15px] flex items-center'}>
                                                View details
                                                <img src={Active} className={'ml-[10px]'}></img>
                                            </div> */}
                                        </div>
                                    </div>}
                                </div>
                            );
                        })}
                    </div>
                    <div className='mt-[25px] max-[1600px]:mt-[15px]'>
                        <div className='font-["Montserrat"] font-[600] max-[1600px]:text-[13px] text-[14px] text-[#818181] mb-[15px]'>Previous</div>
                        { Previous.map((data, i) => {
                            return (
                                <div className="bg-[#FFFFFF] shadow-[0px_2px_6px_0px_rgba(101,106,116,0.25)] py-[20px] px-[15px] rounded-[8px] mb-[8px]" key={i}>
                                    {console.log('data type: ', data)}
                                    {data.type === 'profile' &&
                                    <div className={'flex justify-center items-start'}>
                                        <img src={Profile} className={'mr-[15px]'}/>
                                        <div className='flex flex-col justify-between'>
                                            <div className={'font-["Montserrat"] font-[400] max-[1600px]:text-[12px] text-[13px] text-[#282828] leading-[17px]'}>
                                                <span className={'font-[500] text-primary'}>{data.patient_name}</span> sent a profile modification request <span className={'font-[500] text-[#000000]'}>{data.medicine}</span>.
                                            </div>
                                            {/* <div className={'font-["Montserrat"] font-[600] max-[1600px]:text-[12px] text-[14px] text-primary leading-[15px] flex items-center'}>
                                                View details
                                                <img src={Active} className={'ml-[10px]'}></img>
                                            </div> */}
                                        </div>
                                    </div>}
                                    {data.type === 'refill' &&
                                    <div className={'flex justify-center items-start'}>
                                        <img src={Medication} className={'mr-[15px]'}/>
                                        <div className='flex flex-col justify-between'>
                                            <div className={'font-["Montserrat"] font-[400] max-[1600px]:text-[12px] text-[13px] text-[#282828] leading-[17px]'}>
                                                <span className={'font-[500] text-primary'}>{data.patient_name}</span> sent a medication refill request for <span className={'font-[500] text-[#000000]'}>{data.medicine}</span>.
                                            </div>
                                            {/* <div className={'font-["Montserrat"] font-[600] max-[1600px]:text-[12px] text-[14px] text-primary leading-[15px] flex items-center'}>
                                                View details
                                                <img src={Active} className={'ml-[10px]'}></img>
                                            </div> */}
                                        </div>
                                    </div>}
                                    {data.type === 'new' &&
                                    <div className={'flex justify-center items-start'}>
                                        <img src={Modified} className={'mr-[15px]'}/>
                                        <div className='flex flex-col justify-between'>
                                            <div className={'font-["Montserrat"] font-[400] max-[1600px]:text-[12px] text-[13px] text-[#282828] leading-[17px]'}>
                                                <span className={'font-[500] text-primary'}>{data.patient_name}</span> sent a request for an appointment with <span className={'font-[500] text-[#000000]'}>{data.doctor_name}</span>.
                                            </div>
                                            <div className={'font-["Montserrat"] font-[400] max-[1600px]:text-[11px] text-[12px] text-[#818181] leading-[15px] max-[1600px]:mt-[8px] mt-[10px] mb-[15px] max-[1600px]:mb-[10px]'}>
                                                {data.modify}
                                            </div>
                                            {/* <div className={'font-["Montserrat"] font-[600] max-[1600px]:text-[12px] text-[14px] text-primary leading-[15px] flex items-center'}>
                                                View details
                                                <img src={Active} className={'ml-[10px]'}></img>
                                            </div> */}
                                        </div>
                                    </div>}
                                    {data.type === 'cancel' &&
                                    <div className={'flex justify-center items-start'}>
                                        <img src={Cancel} className={'mr-[15px]'}/>
                                        <div className='flex flex-col justify-between'>
                                            <div className={'font-["Montserrat"] font-[400] max-[1600px]:text-[12px] text-[13px] text-[#282828] leading-[17px]'}>
                                                <span className={'font-[500] text-primary'}>{data.patient_name}</span> sent a request to cancel the appointment for <span className={'font-[500] text-[#000000]'}>{data.date}</span>.
                                            </div>
                                            <div className={'font-["Montserrat"] font-[400] max-[1600px]:text-[11px] text-[12px] text-[#818181] leading-[15px] max-[1600px]:mt-[8px] mt-[10px] mb-[15px] max-[1600px]:mb-[10px]'}>
                                                {data.time}
                                            </div>
                                            {/* <div className={'font-["Montserrat"] font-[600] max-[1600px]:text-[12px] text-[14px] text-primary leading-[15px] flex items-center'}>
                                                View details
                                                <img src={Active} className={'ml-[10px]'}></img>
                                            </div> */}
                                        </div>
                                    </div>}
                                    {data.type === 'chat' &&
                                    <div className={'flex justify-center items-start'}>
                                        <div className='min-w-[35px] h-[35px] mr-[15px] font-[600] font-["Montserrat"] text-[14px] leading-[17px] text-[#FFFFFF] bg-primary rounded-full flex justify-center items-center'>
                                            {data.name?.split(' ').map((name) => name[0])}
                                        </div>
                                        <div className='flex flex-col justify-between'>
                                            <div className={'font-["Montserrat"] font-[600] max-[1600px]:text-[13px] text-[14px] text-primary leading-[17px]'}>
                                            You have new message from {data.name}.
                                            </div>
                                            <div className={'font-["Montserrat"] font-[400] max-[1600px]:text-[11px] text-[12px] text-[#818181] leading-[15px] max-[1600px]:mt-[8px] mt-[10px] mb-[15px] max-[1600px]:mb-[10px]'}>
                                                {data.time}
                                            </div>
                                            <Link to='/dashboard/chat'><div className={'font-["Montserrat"] font-[600] text-[14px] text-primary leading-[15px] flex items-center'} onClick={() => props.setNotify(false)}>
                                                Go to chat
                                                <img src={Active} className={'ml-[10px]'}></img>
                                            </div></Link>
                                        </div>
                                    </div>}
                                    {data.type === 'modified' &&
                                    <div className={'flex justify-center items-start'}>
                                        <img src={Modified} className={'mr-[15px]'}/>
                                        <div className='flex flex-col justify-between'>
                                            <div className={'font-["Montserrat"] font-[400] max-[1600px]:text-[12px] text-[13px] text-[#282828] leading-[17px]'}>
                                                An appointment for <span className={'font-[500] text-primary'}>{data.patient_name}</span> with <span className={'font-[500] text-[#000000]'}>{data.doctor_name}</span> was modified.
                                            </div>
                                            <div className={'font-["Montserrat"] font-[400] max-[1600px]:text-[11px] text-[12px] text-[#818181] leading-[15px] max-[1600px]:mt-[8px] mt-[10px] mb-[15px] max-[1600px]:mb-[10px]'}>
                                                {data.time}
                                            </div>
                                            {/* <div className={'font-["Montserrat"] font-[600] max-[1600px]:text-[12px] text-[14px] text-primary leading-[15px] flex items-center'}>
                                                View details
                                                <img src={Active} className={'ml-[10px]'}></img>
                                            </div> */}
                                        </div>
                                    </div>}
                                </div>
                            );
                        })}
                    </div>
                </div>
                <Link to={'notification'}>
                    <div
                        className='flex justify-center items-center h-[47px] font-["Montserrat"] font-[600] text-[14px] text-primary bg-[#ffffff]'
                        onClick={() => props.setNotify(false)}
                    >
                        View all
                    </div>
                </Link>
            </div>
        </div>
    );
}

export default NotifySide;
