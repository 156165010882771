import React, { useEffect, useState } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import Button from '../../../components/Button';
import Back from '../../../assets/back.svg';

function History () {
    const [val, setVal] = useState();

    useEffect(() => {
        setVal(useLocation().state);
    }, []);

    return (
        <div className='relative transition-all duration-[2s]'>
            <div className='mx-[40px] z-[0] pt-[50px]'>
                <div className='flex items-center mb-[30px]'>
                    <img src={Back} className='w-[20px] h-[15px]' alt='back' />
                    <Link to='/dashboard/modifications'><Button
                        text={'Back'}
                        style={'font-["Montserrat"] contents font-[500] text-[16px] leading-[20px] text-primary text-center rounded-[10px] h-[15px] gap-[10px]'}
                    /></Link>
                </div>
                <div className="flex mb-[30px]">
                    <label className='text-[#282828] font-["Montserrat"] font-[600] text-[26px] leading-[32px]'>
                    New appointment
                    </label>
                </div>

                <div className="w-full flex relative mb-[15px] font-['Montserrat'] bg-[#FFFFFF] shadow-[0px_3px_15px_0px_rgba(186,186,186,0.5)] rounded-[8px] p-[20px]">
                    <div className="flex items-center w-[20%]">
                        <div className='w-[44px] h-[44px] font-[600] text-[14px] leading-[17px] text-[#FFFFFF] bg-primary rounded-full flex justify-center items-center'>
                            {(`${val.patient_fname} ${val.patient_lname}`).split(' ').map((name) => name[0])}
                        </div>
                        <div className="ml-[18px] flex flex-col justify-between">
                            <span>{`${val.patient_fname} ${val.patient_lname}`}</span>
                            <span className='text-[#818181] text-[12px]'>#{val.patient_chart_number}</span>
                        </div>
                    </div>

                    <div className='bg-[#E0E0E0] w-[2px]'></div>

                    <div className='w-[calc(80%-32px)] m-[20px]'>
                        <div className='flex flex-row justify-between'>
                            <div className='w-1/3 flex flex-col h-[100%] justify-around'><label className='font-[600] text-[14px] leading-[17px] my-[5px] text-primary'>Request Date</label><label className='text-[14px] font-[400] leading-[17px] my-[5px] text-[#000000]'>{val.created_date}</label></div>
                            <div className='w-1/3 flex flex-col h-[100%] justify-around'><label className='font-[600] text-[14px] leading-[17px] my-[5px] text-primary'>Appointment Date</label><label className='text-[14px] font-[400] leading-[17px] my-[5px] text-[#000000]'>{val.action_description.appointment_date} {val.action_description.appointment_time}</label></div>
                            <div className='w-1/3 flex flex-col h-[100%] justify-around'><label className='font-[600] text-[14px] leading-[17px] my-[5px] text-primary'>Appointment Location</label><label className='text-[14px] font-[400] leading-[17px] my-[5px] text-[#000000]'>{val.action_description.appointment_location}</label></div>
                        </div>
                        <div className='flex flex-row justify-between'>
                            <div className='w-1/3 flex flex-col h-[100%] justify-around'><label className='font-[600] text-[14px] leading-[17px] my-[5px] text-primary'>Doctor</label><label className='text-[14px] font-[400] leading-[17px] my-[5px] text-[#000000]'>{val.action_description.doctor_name}</label></div>
                            <div className='w-1/3 flex flex-col h-[100%] justify-around'><label className='font-[600] text-[14px] leading-[17px] my-[5px] text-primary'>Purpose</label><label className='text-[14px] font-[400] leading-[17px] my-[5px] text-[#000000]'>{val.action_description.appointment_purpose}</label></div>
                            <div className='w-1/3 flex flex-col h-[100%] justify-around'><label className='font-[600] text-[14px] leading-[17px] my-[5px] text-primary'>Status</label><label className={`text-[14px] font-[400] leading-[17px] my-[5px] p-1 rounded ${val.status === 'CLOSED' ? 'bg-[rgba(255,98,79,0.15)]  text-[#FF624F]' : 'bg-[rgba(0,121,188,0.15)] text-[#016095]'}`} style={{ width: 'fit-content' }}>{val.status}</label></div>
                            {/* <div className='w-[15%] flex flex-row h-[100%] justify-end items-center'><div className='flex'><select onChange={(e) => { setVal(e.target.value); }} className={`outline-none border-none rounded-[6px] w-[124px] h-[27px] px-[5px] ${val === 'New' ? 'bg-[rgba(255,98,79,0.15)] text-[#FF624F]' : 'bg-[rgba(0,121,188,0.15)] text-[#016095]'}`} value={val}>
                                <option className='bg-[rgba(255,98,79,0.15)]  text-[#FF624F]' value={'New'}>New</option>
                                <option className='bg-[rgba(0,121,188,0.15)] text-[#016095]' value={'Pending'}>Pending</option>
                            </select></div>
                            </div> */}
                        </div>
                    </div>
                </div>

                <Outlet/>

            </div>
        </div>
    );
}

export default History;
