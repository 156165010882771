/* eslint-disable */
// this is an auto generated file. This will be overwritten

import gql from "graphql-tag";

export const listRooms = /* GraphQL */ gql`
  query ListRooms(
    $limit: Int
    $owner: String!
  ) {
    listRooms(
      limit: $limit
      owner: $owner
      ) {
      items {
        id
        name
        createdAt
        updatedAt
        owners
      }
    }
  }
`;

export const listMessagesForRoom = /* GraphQL */ gql`
  query ListMessagesForRoom(
    $roomId: ID
    $sortDirection: ModelSortDirection
  ) {
    listMessagesForRoom(
      roomId: $roomId
      sortDirection: $sortDirection
    ) {
      items {
        id
        content
        owner
        createdAt
        roomId
      }
    }
  }
`;
