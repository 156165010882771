/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import { setUserName, setUserID, setHospital, setEmail, setLoader } from '../store/userReducer';
import PushNotification from '../utils/PushNotification';
import axios from '../utils/axios';
import { config } from '../utils/config';
const AuthUser = async (params, dispatchUserInfo) => {
    dispatchUserInfo(
        setLoader(
            true
        )
    );
    const AccessToken = localStorage.getItem('userInfo');
    return await ValidateAuthToken(AccessToken, dispatchUserInfo);
};

const ValidateAuthToken = async (Auth, dispatchUserInfo) => {
    try {
        const UserInfo = JSON.parse(Auth);
        if (UserInfo.type === 'bitrix') {
            return await axios
                .post(
                    `${config[config.APPNAME].API}/get-doctor-info`,
                    {
                        userid: UserInfo.auth.user_id.toString(),
                        accesstoken: UserInfo.auth.AuthenticationResult.AccessToken
                    }, {
                    withCredentials: false
                }
                )
                .then((response) => {
                    const res = response.data.message;
                    // const res = JSON.parse(response.data.message);
                    localStorage.setItem('practice', res?.result?.[0]?.practiceid);
                    if (res.result[0].ID) {
                        dispatchUserInfo(
                            setUserName(
                                res.result[0].NAME + ' ' + res.result[0].LAST_NAME
                            )
                        );
                        dispatchUserInfo(
                            setEmail(
                                res.result[0].EMAIL
                            )
                        );
                        dispatchUserInfo(
                            setHospital(res?.result?.[0]?.practiceid)
                        );
                        dispatchUserInfo(
                            setUserID(
                                res.result[0].ID
                            )
                        );
                        dispatchUserInfo(
                            setLoader(
                                false
                            )
                        );
                        return true;
                    } else return false;
                })
                .catch((error) => {
                    // eslint-disable-next-line no-console
                    if (error.response.status === 403) {
                        PushNotification('error', error.response.data.message);
                    } else {
                        PushNotification('error', 'Error occuerd! please try again later.');
                    }
                    console.log(error);
                    return false;
                });
        } else if (UserInfo.type === 'cognito') {
            return await axios
                .post(
                    `${config[config.APPNAME].API}/staff/get-user-info`,
                    {},
                    {
                        headers: {
                            accesstoken: UserInfo.auth.AuthenticationResult.AccessToken,
                            Authorization: UserInfo.auth.AuthenticationResult.IdToken
                        }
                    }
                )
                .then((response) => {
                    if (response.data.status === 200) {
                        dispatchUserInfo(setUserName(response.data.message.Username));
                        // eslint-disable-next-line no-console
                        const hos = response.data.message.UserAttributes[response.data.message.UserAttributes.findIndex((data) => data.Name === 'custom:practiceid')].Value.toLowerCase();
                        localStorage.setItem('practice', hos);
                        dispatchUserInfo(
                            setHospital(
                                hos
                            )
                        );
                        dispatchUserInfo(
                            setEmail(
                                response.data.message.UserAttributes[response.data.message.UserAttributes.findIndex((data) => data.Name === 'email')].Value
                            )
                        );
                        dispatchUserInfo(
                            setLoader(
                                false
                            )
                        );
                        return true;
                    } else return false;
                })
                .catch((error) => {
                    // eslint-disable-next-line no-console
                    console.log(error);
                    return false;
                });
        } else {
            // eslint-disable-next-line no-console
            return false;
        }
    } catch (err) {
        return false;
    }
};
export default AuthUser;
