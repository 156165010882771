import React, { useEffect, useState } from 'react';
import { Navigate, Outlet, useSearchParams } from 'react-router-dom';

const PrivateRouter = ({ HandleAuthentication }) => {
    const [loading, setLoading] = useState(true);
    const [AuthResponse, setAuthResponse] = useState(false);
    const params = useSearchParams();
    useEffect(() => {
        (async () => {
            setLoading(true);
            const AuthResHandler = await HandleAuthentication(params[0].get('code'));
            setAuthResponse(AuthResHandler);
            setLoading(false);
        })();
    }, []);
    if (loading) return <></>;
    return AuthResponse ? <Outlet /> : <Navigate to="/" />;
};
export default PrivateRouter;
