/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React from 'react';
import { useSelector } from 'react-redux';
import './App.css';
import Loader from './components/Loader';
import Index from './routes';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App () {
    const { userInfo } = useSelector(state => state.userInfo);

    return (
        <div>
            <div className='w-[100vw] h-[100vh] absolute z-[1]'>
                <Loader showSpinner={userInfo.loader}/>
            </div>
            <Index />
            <ToastContainer/>
        </div>
    );
}

export default App;
