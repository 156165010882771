export const config = {
    API: {
        CHAT: 'https://rpxz8kwmxe.execute-api.ap-south-1.amazonaws.com',
        DEV: 'https://332m0um518.execute-api.us-east-2.amazonaws.com/dev/staff',
        LOG: 'https://332m0um518.execute-api.us-east-2.amazonaws.com/dev'
    },
    APPNAME: 'jit-prod',
    'jit-local': {
        CLIENTID: 'local.64759699a7d856.71181234',
        AUTH_API: 'https://portal-dev.coherenteyecare.com/oauth/authorize/?client_id=local.64759699a7d856.71181234&response_type=code',
        WEB: 'https://clinicweb-dev.clearhealthcare.ai',
        API: 'https://api-clinicweb-dev.clearhealthcare.ai',
        CLOUD_LOG: 'https://cloudwatch.amazonaws.com/dashboard.html?dashboard=CloudWatch-dev-user-invocation&context=eyJSIjoidXMtZWFzdC0xIiwiRCI6ImN3LWRiLTkwOTA0NDI2NTQzMSIsIlUiOiJ1cy1lYXN0LTFfVzdXZ05wa216IiwiQyI6IjNnczU0NzViaWxlOWRlbHA4b2NtZTdmdWlyIiwiSSI6InVzLWVhc3QtMTo3NDJlNDQwNS03ZjVmLTRhMDctYjljNS0wZDJiNDllZDViNDEiLCJPIjoiYXJuOmF3czppYW06OjkwOTA0NDI2NTQzMTpyb2xlL3NlcnZpY2Utcm9sZS9DV0RCU2hhcmluZy1SZWFkT25seUFjY2Vzcy1TUTZLQkNNUCIsIk0iOiJVc3JQd1NpbmdsZSJ9&start=PT3H&end=null'
    },
    'jit-dev': {
        CLIENTID: 'local.646b3305ab5b33.47748057',
        AUTH_API: 'https://portal-dev.coherenteyecare.com/oauth/authorize/?client_id=local.646b3305ab5b33.47748057&response_type=code',
        WEB: 'https://clinicweb-dev.clearhealthcare.ai',
        API: 'https://api-clinicweb-dev.clearhealthcare.ai',
        CLOUD_LOG: 'https://cloudwatch.amazonaws.com/dashboard.html?dashboard=CloudWatch-dev-user-invocation&context=eyJSIjoidXMtZWFzdC0xIiwiRCI6ImN3LWRiLTkwOTA0NDI2NTQzMSIsIlUiOiJ1cy1lYXN0LTFfVzdXZ05wa216IiwiQyI6IjNnczU0NzViaWxlOWRlbHA4b2NtZTdmdWlyIiwiSSI6InVzLWVhc3QtMTo3NDJlNDQwNS03ZjVmLTRhMDctYjljNS0wZDJiNDllZDViNDEiLCJPIjoiYXJuOmF3czppYW06OjkwOTA0NDI2NTQzMTpyb2xlL3NlcnZpY2Utcm9sZS9DV0RCU2hhcmluZy1SZWFkT25seUFjY2Vzcy1TUTZLQkNNUCIsIk0iOiJVc3JQd1NpbmdsZSJ9&start=PT3H&end=null'
    },
    'jit-prod': {
        CLIENTID: 'local.647e1a979612b6.09401735',
        AUTH_API: 'https://portal.coherenteyecare.com/oauth/authorize/?client_id=local.647e1a979612b6.09401735&response_type=code',
        WEB: 'https://clinicweb.clearhealthcare.ai',
        API: 'https://api-clinicweb.clearhealthcare.ai',
        CLOUD_LOG: 'https://cloudwatch.amazonaws.com/dashboard.html?dashboard=CloudWatch-prod-user-invocation&context=eyJSIjoidXMtZWFzdC0xIiwiRCI6ImN3LWRiLTkwOTA0NDI2NTQzMSIsIlUiOiJ1cy1lYXN0LTFfVzdXZ05wa216IiwiQyI6IjNnczU0NzViaWxlOWRlbHA4b2NtZTdmdWlyIiwiSSI6InVzLWVhc3QtMTo3NDJlNDQwNS03ZjVmLTRhMDctYjljNS0wZDJiNDllZDViNDEiLCJPIjoiYXJuOmF3czppYW06OjkwOTA0NDI2NTQzMTpyb2xlL3NlcnZpY2Utcm9sZS9DV0RCU2hhcmluZy1SZWFkT25seUFjY2Vzcy1LWTFFUzBUUyIsIk0iOiJVc3JQd1NpbmdsZSJ9&start=PT3H&end=null'
    }
};
