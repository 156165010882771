import React from 'react';
import Button from '../../../../components/Button';
import Back from '../../../../assets/back.svg';
import { Link } from 'react-router-dom';

function Terms () {
    return (
        <div className={'w-[100%] h-full'}>
            <div className='relative'>
                <div className='flex items-center mb-[35px]'>
                    <img src={Back} className='w-[20px] h-[15px]' alt='back' />
                    <Link to={'/dashboard/profile'}>
                        <Button
                            text={'Back'}
                            style={'font-["Montserrat"] contents font-[500] text-[16px] leading-[20px] text-primary text-center rounded-[10px] gap-[10px]'}
                        />
                    </Link>
                </div>
                <div className='mb-[30px] max-[480px]:w-[325px] max-[480px]:text-[18px] font-["Montserrat"] font-[600] text-[28px] leading-[34px] text-[#282828]'>
                    Terms & Conditions
                </div>
                <div>
                    <p className='font-["Montserrat"] font-[400] text-[16px] text-[#000000] leading-[20px] mb-[25px]'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                    <p className='font-["Montserrat"] font-[400] text-[16px] text-[#000000] leading-[20px] mb-[25px]'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                    <p className='font-["Montserrat"] font-[400] text-[16px] text-[#000000] leading-[20px] mb-[25px]'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                </div>
            </div>
        </div>
    );
}

export default Terms;
