/* eslint-disable no-unused-vars */
import React from 'react';
import { Outlet, Link, useLocation } from 'react-router-dom';
import Gt from '../../../assets/gt.svg';

function Profile () {
    const location = useLocation().pathname;
    return (
        <div className='w-full h-full overflow-hidden'>
            <div className={`my-[40px] z-[0] flex justify-between w-[200%] ${location === '/dashboard/profile' ? 'translate-x-[0%]' : 'translate-x-[-50%]'} duration-[1s]`}>
                <div className='mx-[40px] w-[calc(50%-80px)]'>
                    <div className='text-[#282828] font-["Montserrat"] font-[600] text-[26px] leading-[32px] mb-[30px]'>
                Profile
                    </div>
                    {/* <Link to={'reset'}><div
                        className='mb-[10px] flex justify-between items-center w-full p-[25px] bg-[#ffffff] text-[#414141] font-["Montserrat"] font-[600] text-[16px] shadow-[0px_3px_15px_0px_rgba(186,186,186,0.5)] rounded-[8px]'
                    >
                Change password
                        <img src={Gt} className={'w-[20px] h-[20px]'}/>
                    </div></Link> */}
                    <Link to={'terms'}><div className='mb-[10px] flex justify-between items-center w-full p-[25px] bg-[#ffffff] text-[#414141] font-["Montserrat"] font-[600] text-[16px] shadow-[0px_3px_15px_0px_rgba(186,186,186,0.5)] rounded-[8px]'>
                Terms & Conditions
                        <img src={Gt} className={'w-[20px] h-[20px]'}/>
                    </div></Link>
                    <Link to={'privacy'}><div className='mb-[10px] flex justify-between items-center w-full p-[25px] bg-[#ffffff] text-[#414141] font-["Montserrat"] font-[600] text-[16px] shadow-[0px_3px_15px_0px_rgba(186,186,186,0.5)] rounded-[8px]'>
                Privacy Policy
                        <img src={Gt} className={'w-[20px] h-[20px]'}/>
                    </div></Link>
                </div>
                <div className='mx-[40px] w-[calc(50%-80px)]'>
                    <Outlet/>
                </div>
            </div>
        </div>
    );
}

export default Profile;
