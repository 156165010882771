import axios from '../utils/axios';
import { config } from '../utils/config';

export const modification = async (body) => {
    return await axios
        .post(`${config[config.APPNAME].API}/staff/get-change-request`, body, {
            headers: {
                accesstoken: JSON.parse(localStorage.getItem('userInfo')).auth
                    .AuthenticationResult.AccessToken,
                Authorization: JSON.parse(localStorage.getItem('userInfo')).auth
                    .AuthenticationResult.IdToken
            }
        })
        .then((response) => {
            // eslint-disable-next-line no-console
            return response.data;
        })
        .catch((error) => {
            // eslint-disable-next-line no-console
            console.log(error);
            return error.response;
        });
};

export const changeRequest = async (body) => {
    return await axios
        .post(`${config[config.APPNAME].API}/staff/post-change-request`, body, {
            headers: {
                accesstoken: JSON.parse(localStorage.getItem('userInfo')).auth
                    .AuthenticationResult.AccessToken,
                Authorization: JSON.parse(localStorage.getItem('userInfo')).auth
                    .AuthenticationResult.IdToken
            }
        })
        .then((response) => {
            // eslint-disable-next-line no-console
            return response.data;
        })
        .catch((error) => {
            // eslint-disable-next-line no-console
            // eslint-disable-next-line no-console
            console.log(
                error.response?.data.message === 'The incoming token has expired'
            );
            return error.response;
        });
};
