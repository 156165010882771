import axios from '../utils/axios';
import { config } from '../utils/config';

export const chart = async (id, body) => {
    return await axios
        .post(`${config[config.APPNAME].API}/staff/patient-appointment/${id}`, body, {
            headers: {
                accesstoken: JSON.parse(localStorage.getItem('userInfo')).auth
                    .AuthenticationResult.AccessToken,
                Authorization: JSON.parse(localStorage.getItem('userInfo')).auth
                    .AuthenticationResult.IdToken
            }
        })
        .then((response) => {
            // eslint-disable-next-line no-console
            return response.data;
        })
        .catch((error) => {
            // eslint-disable-next-line no-console
            console.log(error);
            return error.response;
        });
};

export const medication = async (id, body) => {
    return await axios
        .post(`${config[config.APPNAME].API}/staff/patient-medication/${id}`, body, {
            headers: {
                accesstoken: JSON.parse(localStorage.getItem('userInfo')).auth
                    .AuthenticationResult.AccessToken,
                Authorization: JSON.parse(localStorage.getItem('userInfo')).auth
                    .AuthenticationResult.IdToken
            }
        })
        .then((response) => {
            // eslint-disable-next-line no-console
            return response.data;
        })
        .catch((error) => {
            // eslint-disable-next-line no-console
            console.log(error);
            return error.response;
        });
};

export const info = async (id, body) => {
    return await axios
        .post(`${config[config.APPNAME].API}/staff/patient-info/${id}`, body, {
            headers: {
                accesstoken: JSON.parse(localStorage.getItem('userInfo')).auth
                    .AuthenticationResult.AccessToken,
                Authorization: JSON.parse(localStorage.getItem('userInfo')).auth
                    .AuthenticationResult.IdToken
            }
        })
        .then((response) => {
            // eslint-disable-next-line no-console
            return response.data;
        })
        .catch((error) => {
            // eslint-disable-next-line no-console
            console.log(error);
            return error.response;
        });
};

export const inviteUser = async (body) => {
    return await axios
        .post(`${config[config.APPNAME].API}/staff/patient-invite-generate-hash`, body, {
            headers: {
                accesstoken: JSON.parse(localStorage.getItem('userInfo')).auth
                    .AuthenticationResult.AccessToken,
                Authorization: JSON.parse(localStorage.getItem('userInfo')).auth
                    .AuthenticationResult.IdToken
            }
        })
        .then((response) => {
            // eslint-disable-next-line no-console
            return response.data;
        })
        .catch((error) => {
            // eslint-disable-next-line no-console
            console.log(error);
            return error.response;
        });
};

export const inviteUserChat = async (id, body) => {
    console.log('sending invite to : ', body);
    return await axios
        .post(`${config[config.APPNAME].API}/staff/patient-chat-init/${id}`, body, {
            headers: {
                accesstoken: JSON.parse(localStorage.getItem('userInfo')).auth
                    .AuthenticationResult.AccessToken,
                Authorization: JSON.parse(localStorage.getItem('userInfo')).auth
                    .AuthenticationResult.IdToken
            }
        })
        .then((response) => {
            // eslint-disable-next-line no-console
            return response.data;
        })
        .catch((error) => {
            // eslint-disable-next-line no-console
            console.log(error);
            return error.response;
        });
};

export const searchPatient = async (body) => {
    return await axios
        .post(`${config[config.APPNAME].API}/staff/search-patient`, body, {
            headers: {
                accesstoken: JSON.parse(localStorage.getItem('userInfo')).auth
                    .AuthenticationResult.AccessToken,
                Authorization: JSON.parse(localStorage.getItem('userInfo')).auth
                    .AuthenticationResult.IdToken
            }
        })
        .then((response) => {
            // eslint-disable-next-line no-console
            return response.data;
        })
        .catch((error) => {
            // eslint-disable-next-line no-console
            console.log(error);
            return error.response;
        });
};

export const getPatienInvitetHistory = async (body) => {
    return await axios
        .post(`${config[config.APPNAME].API}/staff/patient-invite-history`, body, {
            headers: {
                accesstoken: JSON.parse(localStorage.getItem('userInfo')).auth
                    .AuthenticationResult.AccessToken,
                Authorization: JSON.parse(localStorage.getItem('userInfo')).auth
                    .AuthenticationResult.IdToken
            }
        })
        .then((response) => {
            // eslint-disable-next-line no-console
            return response.data;
        })
        .catch((error) => {
            // eslint-disable-next-line no-console
            console.log(error);
            return error.response;
        });
};
