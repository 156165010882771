import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { getPresignedUrl } from '../../api/getPresignedUrl';
import { Document, Page, pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PdfModal = ({ isOpen, toggleModal, closeOnOutsideClick, pdfData }) => {
    const modalRef = useRef(null);
    // const [numPages, setNumPages] = useState(null);
    // const [pageNumber, setPageNumber] = useState(1);
    const pageNumber = 1;
    const [fileUrl, setFileUrl] = useState('');

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (closeOnOutsideClick && modalRef.current && !modalRef.current.contains(event.target)) {
                console.log('outclick2');
                toggleModal();
            }
        };
        document.body.style.overflow = 'hidden';
        // Bind the event listener
        document.getElementById('portal-modal').addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener
            document.body.style.overflow = 'unset';
            document.getElementById('portal-modal').removeEventListener('mousedown', handleClickOutside);
        };
    }, [modalRef, closeOnOutsideClick, toggleModal]);

    useEffect(() => {
        fetchFileUrl();
    }, [pdfData.summary_filename]);

    const handleErr = (e) => {
        console.error('err message: ', e);
        setTimeout(toggleModal, 500);
    };

    const practice = (localStorage.getItem('practice'));

    const fetchFileUrl = async () => {
        const presignedUrl = await getPresignedUrl(practice, 'patientsummary', pdfData.chart_number, pdfData.summary_filename);
        setFileUrl(presignedUrl);
    };

    function onDocumentLoadSuccess ({ numPages }) {
        // setNumPages(numPages);
    }

    return ReactDOM.createPortal(
        <div className={`modal fixed flex justify-center items-center z-[1000] overflow-scroll w-full left-0 top-0 bg-black/[0.4] ${!fileUrl && 'h-full'}`} style={isOpen ? { display: 'flex' } : null}>
            <div ref={modalRef}>
                {fileUrl
                    ? (
                        <Document file={{ url: fileUrl.url }} onLoadError={handleErr} onLoadSuccess={onDocumentLoadSuccess} >
                            <Page pageNumber={pageNumber}/>
                        </Document>
                    )
                    : (<p style={{ color: 'white' }}>Loading...</p>)
                }
            </div>
        </div>,
        document.getElementById('portal-modal')
    );
};

export default PdfModal;
