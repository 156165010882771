import React from 'react';

const TextField = ({ onChange, onClick, onFocus, onBlur, disabled, style, text, textStyle, placeholder, name, id, pattern, required, type, defaultValue, value, onKeyDown }) => {
    return (
        <><br/>
            <input type={type} required={required} onChange={onChange} onFocus={onFocus} onBlur={onBlur} className={style} id={id} name={name} pattern={pattern} disabled={disabled} placeholder={placeholder} value={value} defaultValue={defaultValue} onKeyDown={onKeyDown}/><span className={textStyle}>{text}</span>
        </>
    );
};

export default TextField;
