import React from 'react';
import spinner from '../../assets/Eclipse-1s-200px.gif';

const Loader = (props) => {
    return (

        <div className='w-full h-full absolute'>
            {props.showSpinner
                ? <div className='h-full bg-[#ffffff]  absolute z-[100] w-full'>
                    <div className='flex justify-center items-center h-full'>
                        <img className='h-16 w-16' src={spinner} alt="" />
                    </div>
                </div>
                : <></>}
        </div>
    );
};

export default Loader;
