/* eslint-disable space-before-function-paren */
import React, { useState } from 'react';
import Cancel from '../../../assets/noteCancel.svg';
import Modified from '../../../assets/noteModified.svg';
import Medication from '../../../assets/pill.svg';
import Activegt from '../../../assets/agt.svg';
import Profile from '../../../assets/profileOn.svg';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
// import Modal from '../../../components/Modal';

function Notification() {
    const [Active, setActive] = useState('all');
    const { userInfo } = useSelector((state) => state.userInfo);

    const New = userInfo.notification;

    const date = new Date().toGMTString().split(' ');

    return (
        <div className='h-full transition-all duration-[2s] w-full'>
            <div className='max-[1600px]:mt-[20px] mx-[40px] z-[0] h-full'>

                <div className="mb-[30px] max-[1600px]:mb-[7px]">
                    <label className='text-[#282828] max-[1600px]:text-[18px] font-["Montserrat"] font-[600] text-[26px] leading-[32px]'>
                        Notification
                    </label>
                </div>
                <div className='w-full flex justify-between'>
                    <div className={'w-[79%] bg-[#ffffff] shadow-[0px_3px_10px_0px_rgba(186,186,186,0.25)] rounded-[10px] font-["Montserrat"] max-h-[500px] overflow-y-auto'}>
                        <div className='flex justify-between items-center border-b-[1px] border-b-[#EDEDED] px-[20px] py-[25px]'>
                            <div className='flex bg-[#ffffff] font-["Montserrat"] max-[1600px]:text-[14px] items-center text-[16px] font-[600] text-primary'>{`${date[1]} ${date[2]}, ${date[3]}`}</div>
                            <select className='border-[1px] border-[#E0E0E0] flex w-[150px] h-[50px] max-[1600px]:w-[100px] max-[1600px]:h-[35px] justify-center px-[5px] rounded-[8px] outline-none text-[#818181] text-[14px] font-[600] font-["Montserrat"]'><option>Day</option><option>Week</option></select>
                        </div>
                        {console.log('NotificationData: ', New, Active)}
                        {Active === 'all' && New.map((data, i) => (
                            <div className="bg-[#FFFFFF] shadow-[0px_2px_6px_0px_rgba(101,106,116,0.25)] py-[20px] px-[15px]" key={i}>
                                {data.type === 'new' &&
                                    <div className={'flex items-start'}>
                                        <img src={Modified} className={'mr-[15px]'} />
                                        <div className='flex flex-col justify-between'>
                                            <div className={'font-["Montserrat"] font-[400] text-[13px] text-[#282828] leading-[17px]'}>
                                                <span className={'font-[500] text-primary'}>{data.patient_name}</span> sent a request for an appointment with <span className={'font-[500] text-[#000000]'}>{data.doctor_name}</span>.
                                            </div>
                                            <div className={'font-["Montserrat"] font-[400] text-[12px] text-[#818181] leading-[15px] mt-[10px] mb-[15px]'}>
                                                {data.time}
                                            </div>
                                            {/* <div className={'font-["Montserrat"] font-[600] text-[14px] text-primary leading-[15px] flex items-center'}>
                                                View details
                                                <img src={Activegt} className={'ml-[10px]'}></img>
                                            </div> */}
                                        </div>
                                    </div>}
                                {data.type === 'profile' &&
                                    <div className={'flex items-start'}>
                                        <img src={Profile} className={'mr-[15px]'} />
                                        <div className='flex flex-col justify-between'>
                                            <div className={'font-["Montserrat"] font-[400] text-[13px] text-[#282828] leading-[17px]'}>
                                                <span className={'font-[500] text-primary'}>{data.patient_name}</span> sent a profile modification request.
                                            </div>
                                            <div className={'font-["Montserrat"] font-[400] text-[12px] text-[#818181] leading-[15px] mt-[10px] mb-[15px]'}>
                                                {data.time}
                                            </div>
                                            {/* <div className={'font-["Montserrat"] font-[600] text-[14px] text-primary leading-[15px] flex items-center'}>
                                                View details
                                                <img src={Activegt} className={'ml-[10px]'}></img>
                                            </div> */}
                                        </div>
                                    </div>}
                                {data.type === 'cancel' &&
                                    <div className={'flex items-start'}>
                                        <img src={Cancel} className={'mr-[15px]'} />
                                        <div className='flex flex-col justify-between'>
                                            <div className={'font-["Montserrat"] font-[400] text-[13px] text-[#282828] leading-[17px]'}>
                                                <span className={'font-[500] text-primary'}>{data.patient_name}</span> sent a request to cancel the appointment for <span className={'font-[500] text-[#000000]'}>{data.date}</span>.
                                            </div>
                                            <div className={'font-["Montserrat"] font-[400] text-[12px] text-[#818181] leading-[15px] mt-[10px] mb-[15px]'}>
                                                {data.time}
                                            </div>
                                            {/* <div className={'font-["Montserrat"] font-[600] text-[14px] text-primary leading-[15px] flex items-center'}>
                                                View details
                                                <img src={Activegt} className={'ml-[10px]'}></img>
                                            </div> */}
                                        </div>
                                    </div>}
                                {data.type === 'modified' &&
                                    <div className={'flex items-start'}>
                                        <img src={Modified} className={'mr-[15px]'} />
                                        <div className='flex flex-col justify-between'>
                                            <div className={'font-["Montserrat"] font-[400] text-[13px] text-[#282828] leading-[17px]'}>
                                                An appointment for <span className={'font-[500] text-primary'}>{data.patient_name}</span> with <span className={'font-[500] text-[#000000]'}>{data.doctor_name}</span> was modified.
                                            </div>
                                            <div className={'font-["Montserrat"] font-[400] text-[12px] text-[#818181] leading-[15px] mt-[10px] mb-[15px]'}>
                                                {data.to_date}
                                            </div>
                                            {/* <div className={'font-["Montserrat"] font-[600] text-[14px] text-primary leading-[15px] flex items-center'}>
                                                View details
                                                <img src={Activegt} className={'ml-[10px]'}></img>
                                            </div> */}
                                        </div>
                                    </div>}
                                {console.warn('noti: ', data.type)}
                                {data.type === 'refill' &&
                                    <div className={'flex items-start'}>
                                        <img src={Medication} className={'mr-[15px]'} />
                                        <div className='flex flex-col justify-between'>
                                            <div className={'font-["Montserrat"] font-[400] text-[13px] text-[#282828] leading-[17px]'}>
                                                <span className={'font-[500] text-primary'}>{data.patient_name}</span> sent a medication refill request for <span className={'font-[500] text-[#000000]'}>{data.medicine}</span>.
                                            </div>
                                            {/* <div className={'font-["Montserrat"] font-[600] text-[14px] text-primary leading-[15px] flex items-center'}>
                                                View details
                                                <img src={Activegt} className={'ml-[10px]'}></img>
                                            </div> */}
                                        </div>
                                    </div>}
                                {data.type === 'chat' &&
                                    <div className={'flex items-start'}>
                                        <div className='min-w-[35px] h-[35px] mr-[15px] font-[600] font-["Montserrat"] text-[14px] leading-[17px] text-[#FFFFFF] bg-primary rounded-full flex justify-center items-center'>
                                            {data.name?.split(' ').map((name) => name[0])}
                                        </div>
                                        <div className='flex flex-col justify-between'>
                                            <div className={'font-["Montserrat"] font-[600] text-[14px] text-primary leading-[17px]'}>
                                                You have new message from {data.name}.
                                            </div>
                                            <div className={'font-["Montserrat"] font-[400] text-[12px] text-[#818181] leading-[15px] mt-[10px] mb-[15px]'}>
                                                {data.time}
                                            </div>
                                            <Link to='/dashboard/chat'><div className={'font-["Montserrat"] font-[600] text-[14px] text-primary leading-[15px] flex items-center'}>
                                                Go to chat
                                                <img src={Activegt} className={'ml-[10px]'}></img>
                                            </div></Link>
                                        </div>
                                    </div>}
                            </div>
                        ))}
                        {Active === 'Modifications' && New.map((data, i) => (
                            <>{data.type === 'modified' &&
                                <div className="bg-[#FFFFFF] shadow-[0px_2px_6px_0px_rgba(101,106,116,0.25)] py-[20px] px-[15px]" key={i}>
                                    <div className={'flex items-start'}>
                                        <img src={Modified} className={'mr-[15px]'} />
                                        <div className='flex flex-col justify-between'>
                                            <div className={'font-["Montserrat"] font-[400] text-[13px] text-[#282828] leading-[17px]'}>
                                                An appointment for <span className={'font-[500] text-primary'}>{data.patient_name}</span> with <span className={'font-[500] text-[#000000]'}>{data.doctor_name}</span> was modified.
                                            </div>
                                            <div className={'font-["Montserrat"] font-[400] text-[12px] text-[#818181] leading-[15px] mt-[10px] mb-[15px]'}>
                                                {data.to_date}
                                            </div>
                                            {/* <div className={'font-["Montserrat"] font-[600] text-[14px] text-primary leading-[15px] flex items-center'}>
                                                View details
                                                <img src={Activegt} className={'ml-[10px]'}></img>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            }</>
                        ))}
                        {Active === 'Chats' && New.map((data, i) => (
                            <>{data.type === 'chat' &&
                                <div className="bg-[#FFFFFF] shadow-[0px_2px_6px_0px_rgba(101,106,116,0.25)] py-[20px] px-[15px]" key={i}>
                                    <div className={'flex items-start'}>
                                        <div className='min-w-[35px] h-[35px] mr-[15px] font-[600] font-["Montserrat"] text-[14px] leading-[17px] text-[#FFFFFF] bg-primary rounded-full flex justify-center items-center'>
                                            {data.name?.split(' ').map((name) => name[0])}
                                        </div>
                                        <div className='flex flex-col justify-between'>
                                            <div className={'font-["Montserrat"] font-[600] text-[14px] text-primary leading-[17px]'}>
                                                You have new message from {data.name}.
                                            </div>
                                            <div className={'font-["Montserrat"] font-[400] text-[12px] text-[#818181] leading-[15px] mt-[10px] mb-[15px]'}>
                                                {data.time}
                                            </div>
                                            <Link to='/dashboard/chat'><div className={'font-["Montserrat"] font-[600] text-[14px] text-primary leading-[15px] flex items-center'}>
                                                Go to chat
                                                <img src={Activegt} className={'ml-[10px]'}></img>
                                            </div></Link>
                                        </div>
                                    </div>
                                </div>}</>
                        ))}
                    </div>
                    <div className={'w-[19%] bg-[#ffffff] shadow-[0px_3px_10px_0px_rgba(186,186,186,0.25)] rounded-[8px] font-["Montserrat"] h-[171px]'}>
                        <div className={`h-[57px] border-b-[1px] border-b-[#EDEDED] flex px-[20px] items-center font-["Montserrat"] text-[14px] font-[600] rounded-t-[8px] ${Active === 'all' ? 'bg-[rgba(0,121,188,0.08)] text-primary' : 'bg-[#ffffff] text-[#282828]'}`} onClick={() => setActive('all')}>All</div>
                        <div className={`h-[57px] border-b-[1px] border-b-[#EDEDED] flex px-[20px] items-center font-["Montserrat"] text-[14px] font-[600] ${Active === 'Modifications' ? 'bg-[rgba(0,121,188,0.08)] text-primary' : 'bg-[#ffffff] text-[#282828]'}`} onClick={() => setActive('Modifications')}>Modifications</div>
                        <div className={`h-[57px] border-b-[1px] border-b-[#EDEDED] flex px-[20px] items-center font-["Montserrat"] text-[14px] font-[600] rounded-b-[8px] ${Active === 'Chats' ? 'bg-[rgba(0,121,188,0.08)] text-primary' : 'bg-[#ffffff] text-[#282828]'}`} onClick={() => setActive('Chats')}>Chats</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Notification;
