/* eslint-disable no-console */
import axios from '../utils/axios';
import { config } from '../utils/config';

export const location = async (body, practice) => {
    return await axios
        .get(`${config[config.APPNAME].API}/staff/${practice}/locations`, {
            headers: {
                accesstoken: JSON.parse(localStorage.getItem('userInfo')).auth
                    .AuthenticationResult.AccessToken,
                Authorization: JSON.parse(localStorage.getItem('userInfo')).auth
                    .AuthenticationResult.IdToken
            }
        })
        .then((response) => {
            // eslint-disable-next-line no-console
            return response.data;
        })
        .catch((error) => {
            // eslint-disable-next-line no-console
            console.log(error);
            return error.response;
        });
};

export const physician = async (body, practice) => {
    return await axios
        .get(`${config[config.APPNAME].API}/staff/${practice}/physicians`, {
            headers: {
                accesstoken: JSON.parse(localStorage.getItem('userInfo')).auth
                    .AuthenticationResult.AccessToken,
                Authorization: JSON.parse(localStorage.getItem('userInfo')).auth
                    .AuthenticationResult.IdToken
            }
        })
        .then((response) => {
            // eslint-disable-next-line no-console
            return response.data;
        })
        .catch((error) => {
            // eslint-disable-next-line no-console
            console.log(error);
            return error.response;
        });
};

export const appttypes = async (body, practice) => {
    return await axios
        .post(
            `${config[config.APPNAME].API}/staff/${practice}/apptclass-to-apptname-mapping`, body,
            {
                headers: {
                    accesstoken: JSON.parse(localStorage.getItem('userInfo')).auth
                        .AuthenticationResult.AccessToken,
                    Authorization: JSON.parse(localStorage.getItem('userInfo')).auth
                        .AuthenticationResult.IdToken
                }
            }
        )
        .then((response) => {
            // eslint-disable-next-line no-console
            return response.data;
        })
        .catch((error) => {
            // eslint-disable-next-line no-console
            console.log(error);
            return error.response;
        });
};

export const appointments = async (body, practice) => {
    return await axios
        .post(`${config[config.APPNAME].API}/staff/${practice}/appt`, body, {
            headers: {
                accesstoken: JSON.parse(localStorage.getItem('userInfo')).auth
                    .AuthenticationResult.AccessToken,
                Authorization: JSON.parse(localStorage.getItem('userInfo')).auth
                    .AuthenticationResult.IdToken
            }
        })
        .then((response) => {
            // eslint-disable-next-line no-console
            return response.data;
        })
        .catch((error) => {
            // eslint-disable-next-line no-console
            console.log(error);
            return error.response;
        });
};

export const medication = async (body, practice) => {
    return await axios
        .post(`${config[config.APPNAME].API}/staff/${practice}/get-med`, body, {
            headers: {
                accesstoken: JSON.parse(localStorage.getItem('userInfo')).auth
                    .AuthenticationResult.AccessToken,
                Authorization: JSON.parse(localStorage.getItem('userInfo')).auth
                    .AuthenticationResult.IdToken
            }
        })
        .then((response) => {
            // eslint-disable-next-line no-console
            console.log('response', response);
            return response.data;
        })
        .catch((error) => {
            // eslint-disable-next-line no-console
            console.log(error);
            return error.response;
        });
};

export const allappt = async (body, practice) => {
    return await axios
        .post(`${config[config.APPNAME].API}/staff/${practice}/appointment-mapping`, body, {
            headers: {
                accesstoken: JSON.parse(localStorage.getItem('userInfo')).auth
                    .AuthenticationResult.AccessToken,
                Authorization: JSON.parse(localStorage.getItem('userInfo')).auth
                    .AuthenticationResult.IdToken
            }
        })
        .then((response) => {
            // eslint-disable-next-line no-console
            return response.data;
        })
        .catch((error) => {
            // eslint-disable-next-line no-console
            console.log(error);
            return error.response;
        });
};

export const sendBroadcastNotification = async (data) => {
    return await axios
        .post(`${config[config.APPNAME].API}/staff/broadcast-notification`, data, {
            headers: {
                accesstoken: JSON.parse(localStorage.getItem('userInfo')).auth
                    .AuthenticationResult.AccessToken,
                Authorization: JSON.parse(localStorage.getItem('userInfo')).auth
                    .AuthenticationResult.IdToken
            }
        })
        .then((response) => {
            // eslint-disable-next-line no-console
            return response.data;
        })
        .catch((error) => {
            // eslint-disable-next-line no-console
            console.log(error);
            return error.response;
        });
};
