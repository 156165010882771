import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import OnboardingRoutes from './OnboardingRoutes';
import DashBoardRoutes from './DashBoardRoutes';
import AuthUser from '../helper/AuthUser';
import PublicRouter from './PublicRouter';
import PrivateRouter from './PrivateRouter';
import { useDispatch } from 'react-redux';

const Index = () => {
    const dispatch = useDispatch();
    const HandleAuthentication = async (params) => {
        const AuthResponse = await AuthUser(params || false, dispatch);
        return AuthResponse;
    };
    return (
        <Router>
            <Routes>
                <Route element={<PublicRouter />}>{OnboardingRoutes}</Route>
                <Route element={<PrivateRouter HandleAuthentication={HandleAuthentication} />}>{DashBoardRoutes}</Route>
            </Routes>
        </Router>
    );
};

export default Index;
