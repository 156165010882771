import axios from '../utils/axios';
import { config } from '../utils/config';

export const getPresignedUrl = (
    preferredPractice,
    entityType,
    chartNum,
    filename = null
) => {
    const data = {
        practice: preferredPractice,
        entity: entityType,
        chart_number: chartNum,
        file_name: filename
    };
    return axios.post(
        `${config[config.APPNAME].API}/staff/presigned-url`,
        data, {
            headers: {
                accesstoken: JSON.parse(localStorage.getItem('userInfo')).auth
                    .AuthenticationResult.AccessToken,
                Authorization: JSON.parse(localStorage.getItem('userInfo')).auth
                    .AuthenticationResult.IdToken
            }
        }
    )
        .then((response) => {
        // eslint-disable-next-line no-console
            return response.data;
        })
        .catch((error) => {
        // eslint-disable-next-line no-console
            console.log(error);
            return error.response;
        });
};
