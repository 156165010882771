import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { config } from '../../utils/config';

function ULToken ({ route, navigation }) {
    const { hash } = useParams();
    useEffect(() => {
        console.log('script triggered', hash, navigator.platform);
        try {
            const webPrefix = config[config.APPNAME].WEB.split('/')[config[config.APPNAME].WEB.split('/').length - 1];
            if (/Android/i.test(navigator.userAgent)) {
                setTimeout(() => { window.location = 'https://play.google.com/store/apps/details?id=com.coherent.clearhealthcare'; }, 100);
                window.location = `${webPrefix}://token/${hash}`;
            } else if (/iPhone/i.test(navigator.userAgent)) {
                setTimeout(function () { window.location = 'https://apps.apple.com/app/clear-health-care/id6450694559'; }, 100);
                window.location = `${webPrefix}://token/${hash}`;
            } else {
                alert('undetected');
            }
        } catch (e) {
            console.log('exception');
        }
    }, []);
    return (
        <div>
            <h2>Loading, please wait...</h2>
        </div>
    );
}

export default ULToken;
