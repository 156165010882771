import axios from 'axios';

export default axios.create({
    // mode: 'no-cors',
    // baseURL: Getapi(),
    headers: {
        'Content-Type': 'application/json'
        // accesstoken: JSON.parse(localStorage.getItem('userInfo')).auth
        //     .AuthenticationResult.AccessToken,
        // Authorization: JSON.parse(localStorage.getItem('userInfo')).auth
        //     .AuthenticationResult.IdToken
    }
});
