/* eslint-disable space-before-function-paren */
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { setLoader } from '../../store/userReducer';
import axios from '../../utils/axios';
import PushNotification from '../../utils/PushNotification';
import { config } from '../../utils/config';

function Register() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const params = useSearchParams();
    useEffect(() => {
        dispatch(
            setLoader(
                true
            )
        );
        axios
            .post(
                `${config[config.APPNAME].API}/get-access-token`, { code: params[0].get('code'), app_name: config.APPNAME }, { withCredentials: false }
            )
            .then(async (response) => {
                localStorage.setItem('userInfo', JSON.stringify({ type: 'bitrix', auth: response.data.message }));
                // PushNotification('success', 'Login Successful');
                navigate('/dashboard/appointments', { replace: true });
                dispatch(
                    setLoader(
                        false
                    )
                );
            })
            .catch((error) => {
                // eslint-disable-next-line no-console
                PushNotification('error', 'Network error');
                console.log('error', error);
            });
    }, []);

    return (
        <div></div>
    );
}

export default Register;
