import React from 'react';

function HistoryOut () {
    // const HisData = [
    //     {
    //         name: 'Kate Lorren',
    //         data: 'Kate Lorren changed the status of this appointment from New to Pending.',
    //         operation: true,
    //         time: '2 hours ago'
    //     },
    //     {
    //         name: 'Kate Lorren',
    //         data: 'Kate Lorren changed the time of this appointment to 26 March, 10:00am.',
    //         operation: false,
    //         time: '2 hours ago'
    //     },
    //     {
    //         name: 'Kate Lorren',
    //         data: 'Kate Lorren changed the status of this appointment from New to Pending.',
    //         operation: true,
    //         time: '5 hours ago'
    //     }
    // ];
    return (
        // <div className={"w-full relative mb-[15px] font-['Montserrat'] bg-[#FFFFFF] shadow-[0px_3px_15px_0px_rgba(186,186,186,0.5)] rounded-[8px] p-[20px] pb-[0px]"}>
        //     <div className='text-[#000000] font-["Montserrat"] font-[600] text-[20px]'>History</div>
        //     <div className='flex justify-between items-center mt-[25px] mb-[15px]'>
        //         <TextField
        //             style={
        //                 'h-[38px] border-[#E0E0E0] border-[1px] rounded-[8px] w-[calc(100%-130px)] mr-[20px] pl-[20px] text-[#414141] outline-none'
        //             }
        //             placeholder={'Leave comment'}
        //         />
        //         <Button text={'Send'} style={'text-[#ffffff] bg-primary py-[12px] px-[18px] rounded-[6px] h-[38px] flex items-center justify-center w-[130px]'}/>
        //     </div>
        //     {
        //         HisData.map((data, i) => {
        //             return (
        //                 <div key={i} className='border-b-[#E0E0E0] border-b-[1px] p-[20px]'>
        //                     <div className={'flex items-center justify-between'}>
        //                         <div className={'flex items-center'}>
        //                             <div className='w-[44px] h-[44px] mr-[20px] font-[600] text-[14px] leading-[17px] text-[#FFFFFF] bg-primary rounded-full flex justify-center items-center'>
        //                                 {(data.name).split(' ').map((name) => name[0])}
        //                             </div>
        //                             <div className='flex flex-col'>
        //                                 <div className='font-["Montserrat"] text-[14px] text-[#414141]'>{data.data}</div>
        //                                 {data.operation && <div className='flex items-center mt-[18px]'><span className='bg-[rgba(255,98,79,0.15)] font-["Montserrat"] font-[500] text-[14px] bg-primary rounded-[6px] text-[#FF624F] py-[3px] px-[6px]'>New</span><img src={Arrow} className={'w-[13px] h-[11px] mx-[13px]'}/><span className='bg-[rgba(0,121,188,0.15)] font-["Montserrat"] font-[500] text-[14px] rounded-[6px] text-[#016095] h-[27px] py-[3px] px-[6px]'>Pending</span></div>}
        //                             </div>
        //                         </div>
        //                         <div className='font-["Montserrat"] text-[12px] text-[#ACACAC] font-[500]'>
        //                             {data.time}
        //                         </div>
        //                     </div>
        //                 </div>
        //             );
        //         })
        //     }
        // </div>
        <></>
    );
}

export default HistoryOut;
