/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import { format } from 'date-fns';
import moment from 'moment';
import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import color from '../../../../utils/json/typeColor.json';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';

function Calendar ({ Appointments, Datess, filter }) {
    const calen = new Date(Datess);
    const check = (value) => {
        const time = ['7 AM', '8 AM', '9 AM', '10 AM', '11 AM', '12 PM', '1 PM', '2 PM', '3 PM', '4 PM', '5 PM', '6 PM', '7 PM', '8 PM', '9 PM'];
        return parseInt(time.findIndex((data) => data === value)) * 240;
    };
    const block = useRef(1);

    const subdividedArray = Appointments?.reduce((acc, currentValue) => {
        const category = currentValue.appt_date;
        const existingSubarray = acc.find(subarray => subarray[0].appt_date === category);
        if (existingSubarray) {
            existingSubarray.push(currentValue);
        } else {
            acc.push([currentValue]);
        }
        return acc;
    }, []);

    const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} placement='right' arrow/>
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#ffffff',
            color: 'rgba(0, 0, 0, 0.87)',
            maxWidth: 220,
            border: '1px solid #dadde9'
        }
    }));

    const wid = (data) => {
        // block.current.clientWidth;
        const temp = new Date(Datess);
        const width = block?.current?.clientWidth / 5 - 17;
        if (data === format(temp, 'yyyy-MM-dd')) {
            // console.log('text', temp, data);
            return 1;
        } else if (data === format(temp.setDate(temp.getDate() + 1), 'yyyy-MM-dd')) {
            // console.log('text', temp, data);
            return width;
        } else if (data === format(temp.setDate(temp.getDate() + 1), 'yyyy-MM-dd')) {
            // console.log('text', temp, data);
            return width * 2;
        } else if (data === format(temp.setDate(temp.getDate() + 1), 'yyyy-MM-dd')) {
            // console.log('text', temp, data);
            return width * 3;
        } else if (data === format(temp.setDate(temp.getDate() + 1), 'yyyy-MM-dd')) {
            // console.log('text', temp, data);
            return width * 4;
        }
    };

    return (
        <div className='relative flex h-[calc(3600px+85px)] w-full overflow-y-scroll text-[#ACACAC] font-["Montserrat"] mb-[20px]'>
            <div className='h-[calc(3360px+85px+10px)] w-full pl-[83px] flex justify-between absolute top-0 left-0'
                ref={block}
            >
                <hr className='border-[#EDEDED] border-[1px] h-full'></hr>
                <div className='mt-[20px] text-center text-[13px] z-[1]'>
                    <div style={{ width: 'calc(17% - 15px)' }} className='text-[#ACACAC] max-[1600px]:w-[15%] font-[500] fixed bg-[#ffffff] translate-x-[-50%] translate-y-[-24%] h-[89px] pt-[15px] border-[#EDEDED] border-b-[3px]'>{moment(format(calen, 'yyyy/MM/dd')).format('ddd').toUpperCase()}</div>
                    <div className='text-[#414141] font-[600] fixed bg-[#ffffff] w-[100px] translate-x-[-50%] translate-y-[70%]'>{moment(format(calen, 'yyyy/MM/dd')).format('MMM DD')}</div>
                </div>
                <hr className='border-[#EDEDED] border-[1px] h-full'></hr>
                <div className='mt-[20px] text-center text-[13px] z-[1]'>
                    <div style={{ width: 'calc(17% - 15px)' }} className='text-[#ACACAC] max-[1600px]:w-[15%] font-[500] fixed bg-[#ffffff] translate-x-[-50%] translate-y-[-24%] h-[89px] pt-[15px] border-[#EDEDED] border-b-[3px]'>{moment(format(calen.setDate(calen.getDate() + 1), 'yyyy/MM/dd')).format('ddd').toUpperCase()}</div>
                    <div className='text-[#414141] font-[600] fixed bg-[#ffffff] w-[100px] translate-x-[-50%] translate-y-[70%]'>{moment(format(calen, 'yyyy/MM/dd')).format('MMM DD')}</div>
                </div>
                <hr className='border-[#EDEDED] border-[1px] h-full'></hr>
                <div className='mt-[20px] text-center text-[13px] z-[1]'>
                    <div style={{ width: 'calc(17% - 15px)' }} className='text-[#ACACAC] max-[1600px]:w-[15%] font-[500] fixed bg-[#ffffff] translate-x-[-50%] translate-y-[-24%] h-[89px] pt-[15px] border-[#EDEDED] border-b-[3px]'>{moment(format(calen.setDate(calen.getDate() + 1), 'yyyy/MM/dd')).format('ddd').toUpperCase()}</div>
                    <div className='text-[#414141] font-[600] fixed bg-[#ffffff] w-[100px] translate-x-[-50%] translate-y-[70%]'>{moment(format(calen, 'yyyy/MM/dd')).format('MMM DD')}</div>
                </div>
                <hr className='border-[#EDEDED] border-[1px] h-full'></hr>
                <div className='mt-[20px] text-center text-[13px] z-[1]'>
                    <div style={{ width: 'calc(17% - 15px)' }} className='text-[#ACACAC] max-[1600px]:w-[15%] font-[500] fixed bg-[#ffffff] translate-x-[-50%] translate-y-[-24%] h-[89px] pt-[15px] border-[#EDEDED] border-b-[3px]'>{moment(format(calen.setDate(calen.getDate() + 1), 'yyyy/MM/dd')).format('ddd').toUpperCase()}</div>
                    <div className='text-[#414141] font-[600] fixed bg-[#ffffff] w-[100px] translate-x-[-50%] translate-y-[70%]'>{moment(format(calen, 'yyyy/MM/dd')).format('MMM DD')}</div>
                </div>
                <hr className='border-[#EDEDED] border-[1px] h-full'></hr>
                <div className='mt-[20px] text-center text-[13px] z-[1]'>
                    <div style={{ width: 'calc(17% - 15px)' }} className='text-[#ACACAC] max-[1600px]:w-[15%] font-[500] fixed bg-[#ffffff] translate-x-[-50%] translate-y-[-24%] h-[89px] pt-[15px] border-[#EDEDED] border-b-[3px]'>{moment(format(calen.setDate(calen.getDate() + 1), 'yyyy/MM/dd')).format('ddd').toUpperCase()}</div>
                    <div className='text-[#414141] font-[600] fixed bg-[#ffffff] w-[100px] translate-x-[-50%] translate-y-[70%]'>{moment(format(calen, 'yyyy/MM/dd')).format('MMM DD')}</div>
                </div>
                <hr className='border-[#EDEDED] border-[1px] h-full'></hr>
            </div>
            <div className='h-full w-full pl-[25px] pt-[15px] flex flex-col absolute top-0 left-0 z-[0]'>
                <div className='flex items-end font-[600] text-[14px] min-h-[72px] max-h-[72px]'><label className='w-[45px] translate-y-[10px] mr-[5px] text-right'>7 AM</label><hr className='border-[#EDEDED] border-[1px] w-[calc(100%-45px)] '></hr></div>
                <div className='flex items-end font-[600] text-[14px] min-h-[240px] max-h-[240px]'><label className='w-[45px] translate-y-[10px] mr-[5px] text-right'>8 AM</label><hr className='border-[#EDEDED] border-[1px] w-[calc(100%-45px)] '></hr></div>
                <div className='flex items-end font-[600] text-[14px] min-h-[240px] max-h-[240px]'><label className='w-[45px] translate-y-[10px] mr-[5px] text-right'>9 AM</label><hr className='border-[#EDEDED] border-[1px] w-[calc(100%-45px)] '></hr></div>
                <div className='flex items-end font-[600] text-[14px] min-h-[240px] max-h-[240px]'><label className='w-[45px] translate-y-[10px] mr-[5px] text-right'>10 AM</label><hr className='border-[#EDEDED] border-[1px] w-[calc(100%-45px)] '></hr></div>
                <div className='flex items-end font-[600] text-[14px] min-h-[240px] max-h-[240px]'><label className='w-[45px] translate-y-[10px] mr-[5px] text-right'>11 AM</label><hr className='border-[#EDEDED] border-[1px] w-[calc(100%-45px)] '></hr></div>
                <div className='flex items-end font-[600] text-[14px] min-h-[240px] max-h-[240px]'><label className='w-[45px] translate-y-[10px] mr-[5px] text-right'>12 PM</label><hr className='border-[#EDEDED] border-[1px] w-[calc(100%-45px)] '></hr></div>
                <div className='flex items-end font-[600] text-[14px] min-h-[240px] max-h-[240px]'><label className='w-[45px] translate-y-[10px] mr-[5px] text-right'>1 PM</label><hr className='border-[#EDEDED] border-[1px] w-[calc(100%-45px)] '></hr></div>
                <div className='flex items-end font-[600] text-[14px] min-h-[240px] max-h-[240px]'><label className='w-[45px] translate-y-[10px] mr-[5px] text-right'>2 PM</label><hr className='border-[#EDEDED] border-[1px] w-[calc(100%-45px)] '></hr></div>
                <div className='flex items-end font-[600] text-[14px] min-h-[240px] max-h-[240px]'><label className='w-[45px] translate-y-[10px] mr-[5px] text-right'>3 PM</label><hr className='border-[#EDEDED] border-[1px] w-[calc(100%-45px)] '></hr></div>
                <div className='flex items-end font-[600] text-[14px] min-h-[240px] max-h-[240px]'><label className='w-[45px] translate-y-[10px] mr-[5px] text-right'>4 PM</label><hr className='border-[#EDEDED] border-[1px] w-[calc(100%-45px)] '></hr></div>
                <div className='flex items-end font-[600] text-[14px] min-h-[240px] max-h-[240px]'><label className='w-[45px] translate-y-[10px] mr-[5px] text-right'>5 PM</label><hr className='border-[#EDEDED] border-[1px] w-[calc(100%-45px)] '></hr></div>
                <div className='flex items-end font-[600] text-[14px] min-h-[240px] max-h-[240px]'><label className='w-[45px] translate-y-[10px] mr-[5px] text-right'>6 PM</label><hr className='border-[#EDEDED] border-[1px] w-[calc(100%-45px)] '></hr></div>
                <div className='flex items-end font-[600] text-[14px] min-h-[240px] max-h-[240px]'><label className='w-[45px] translate-y-[10px] mr-[5px] text-right'>7 PM</label><hr className='border-[#EDEDED] border-[1px] w-[calc(100%-45px)] '></hr></div>
                <div className='flex items-end font-[600] text-[14px] min-h-[240px] max-h-[240px]'><label className='w-[45px] translate-y-[10px] mr-[5px] text-right'>8 PM</label><hr className='border-[#EDEDED] border-[1px] w-[calc(100%-45px)] '></hr></div>
                <div className='flex items-end font-[600] text-[14px] min-h-[240px] max-h-[240px]'><label className='w-[45px] translate-y-[10px] mr-[5px] text-right'>9 PM</label><hr className='border-[#EDEDED] border-[1px] w-[calc(100%-45px)] '></hr></div>
            </div>
            <div className='h-[calc(3400px+85px)] w-full pl-[83px] pt-[85px] flex flex-col absolute top-0 left-0 z-[0]'>
                <div className='relative h-full w-full'>
                    {
                        subdividedArray?.map((data1, i) => (<div className='' key={i}>{
                            data1.map((data, i) =>
                                filter
                                    ? (filter !== '' && ([data?.pat_name, (data?.patient_fname + ' ' + data?.patient_lname), data?.chart_number, data?.patient_chart_number, data?.phy_name, data?.action_description?.doctor_name, data?.appt_reason, data?.action_description?.appointment_location, data?.action_description?.appointment_purpose, data?.action_description?.appt_reason, data?.action_description?.medication_text, (data?.action_description?.physician_fname + ' ' + data?.action_description?.physician_lname), data?.action_description?.name, data?.action_description?.location_name, data?.action_description?.reason_for_cancelation].some(element => element?.toString().toLowerCase().includes(filter.toLowerCase())))
                                        // eslint-disable-next-line multiline-ternary
                                        ? (<HtmlTooltip
                                            key={i}
                                            title={
                                                <React.Fragment>
                                                    {/* <Typography color="inherit">Details</Typography> */}
                                                    {<div className='text-[14px]'>{data.appt_reason}</div>}
                                                </React.Fragment>
                                            }
                                        >
                                            <div style={
                                                {
                                                    position: 'absolute',
                                                    top: (check((moment(data.appt_time, 'HH:mm:ss').format('h A'))) + (parseInt(moment(data.appt_time, 'HH:mm:ss').format('mm'))) * 4) + 'px',
                                                    left: (wid(data.appt_date)) + 'px'
                                                }
                                            }
                                            className={'left-[1px] rounded-b-[6px] absolute w-1/5 max-h-[20px] items-center h-auto bg-[#ffffff] shadow-[0px_4px_12px_rgba(183,183,183,0.3)]'}>
                                                <Link to='chart' state={{ name: data.pat_name, chart_id: data.chart_number }}>
                                                    <div style={{ borderColor: color[color.findIndex((row) => data.appt_reason.toLowerCase().includes(row.name.toLowerCase()))]?.color ? color[color?.findIndex((row) => data.appt_reason.toLowerCase().includes(row.name.toLowerCase()))]?.color : '#0079BC' }} className='border-[2px] w-full rounded-b-[4px]'></div>
                                                    <div className='flex justify-between flex-wrap px-[10px] border-none'>
                                                        <div className='font-[600] max-[1600px]:text-[9px] text-[12px] text-[#414141]'>{data.pat_name}</div>
                                                        <div className='text-[12px] max-[1600px]:text-[9px] text-[#818181]'>{(moment(data.appt_time, 'HH:mm:ss').format('hh:mm A'))}</div>
                                                    </div>
                                                </Link>
                                            </div>
                                        </HtmlTooltip>
                                        ) : null
                                    )
                                    : (<HtmlTooltip
                                        key={i}
                                        title={
                                            <React.Fragment>
                                                {/* <Typography color="inherit">Details</Typography> */}
                                                {<div className='text-[14px]'>{data.appt_reason}</div>}
                                            </React.Fragment>
                                        }
                                    >
                                        <div style={
                                            {
                                                position: 'absolute',
                                                top: (check((moment(data.appt_time, 'HH:mm:ss').format('h A'))) + (parseInt(moment(data.appt_time, 'HH:mm:ss').format('mm'))) * 4) + 'px',
                                                left: (wid(data.appt_date)) + 'px'
                                            }
                                        }
                                        className={'left-[1px] rounded-b-[6px] absolute w-1/5 max-h-[20px] items-center h-auto bg-[#ffffff] shadow-[0px_4px_12px_rgba(183,183,183,0.3)]'}>
                                            <Link to='chart' state={{ name: data.pat_name, chart_id: data.chart_number }}>
                                                <div style={{ borderColor: color[color.findIndex((row) => data.appt_reason.toLowerCase().includes(row.name.toLowerCase()))]?.color ? color[color?.findIndex((row) => data.appt_reason.toLowerCase().includes(row.name.toLowerCase()))]?.color : '#0079BC' }} className='border-[2px] w-full rounded-b-[4px]'></div>
                                                <div className='flex justify-between flex-wrap px-[10px] border-none'>
                                                    <div className='font-[600] max-[1600px]:text-[9px] text-[12px] text-[#414141]'>{data.pat_name}</div>
                                                    <div className='text-[12px] max-[1600px]:text-[9px] text-[#818181]'>{(moment(data.appt_time, 'HH:mm:ss').format('hh:mm A'))}</div>
                                                </div>
                                            </Link>
                                        </div>
                                    </HtmlTooltip>
                                    ))
                        }</div>)
                        )
                    }
                    {/* {Appointments.map((data, i) =>
                        (<div key={i} style={
                            {
                                position: 'absolute',
                                top: (check((moment(data.appt_time, 'HH:mm:ss').format('h A'))) + (parseInt(moment(data.appt_time, 'HH:mm:ss').format('mm'))) * 4) + 'px',
                                left: block.current.clientWidth * 2 + 'px'
                            }
                        }
                        ref={block}
                        className={'left-[1px] rounded-b-[6px] absolute w-1/5 max-h-[20px] items-center h-auto bg-[#ffffff] shadow-[0px_4px_12px_rgba(183,183,183,0.3)]'}>
                            <Link to='chart' state={{ name: data.pat_name, chart_id: data.chart_number }}>
                                <div className='border-[2px] w-full rounded-b-[4px] border-primary'></div>
                                <div className='flex justify-between flex-wrap px-[10px] border-none'>
                                    <div className='font-[600] text-[12px] text-[#414141]'>{data.pat_name}</div>
                                    <div className='text-[12px] text-[#818181]'>{(moment(data.appt_time, 'HH:mm:ss').format('hh:mm A'))}</div>
                                </div>
                            </Link>
                        </div>
                        )
                    )
                    } */}
                </div>
            </div>
        </div>
    );
}

export default Calendar;
