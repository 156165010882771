/* eslint-disable space-before-function-paren */
/* eslint-disable indent */
/* eslint-disable no-console */
import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../../components/Button';
import { config } from '../../utils/config';

function SignIn() {
    // const [Visib1ility, setVisibility] = useState(false);
    return (
        <div className="bg-[#F9FAFD] w-full overflow-hidden h-[100vh] relative z-[2]">
            <div className="z-[1] flex flex-row justify-between items-center py-[10px] max-[1600px]:px-[35px] px-[50px] gap-[15px] fixed w-full  max-[1600px]:h-[8%] h-[75px] bg-[#FFFFFF]">
                <Link to={'/'}>
                    <label className='text-primary font-["Montserrat"] font-[500] max-[1600px]:text-[18px] text-[22px] leading-[27px]'>
                        {localStorage.getItem('title')}
                    </label>
                </Link>
            </div>

            <div className="w-[100vw] h-full flex items-center justify-center">
                <div className="relative">
                    <div className="w-full flex flex-col justify-center items-center">
                        <Link
                            to={config[config.APPNAME].AUTH_API}
                        >
                            <Button
                                text={'Sign In with Bitrix'}
                                // style='font-["Montserrat"] max-[1600px]:mt-[0px] mt-[10px] font-[600] max-[1600px]:text-[14px] text-[16px] leading-[20px] text-primary text-center rounded-[10px] gap-[10px] w-[206px] h-[55px]'
                                style={'outline-none font-["Montserrat"] font-[500] max-[1600px]:text-[13px] text-[16px] leading-[20px] text-[#FFFFFF] text-center rounded-[10px] gap-[10px] max-[1600px]:w-[170px] max-[1600px]:h-[45px] w-[206px] h-[55px] bg-primary'}
                            />
                        </Link>
                    </div>
                </div>
            </div >
        </div >
    );
}

export default SignIn;
