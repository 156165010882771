import React, { useState } from 'react';
import TextField from '../../../components/TextField';
import Search from '../../../assets/searchWhite.svg';
import Table from '../../../components/Table';
// import Chat from '../../../assets/actionChat.svg';
// import Details from '../../../assets/actionDetails.svg';
// import Edit from '../../../assets/edit.svg';
import { Link } from 'react-router-dom';
import { Button, CircularProgress } from '@mui/material';
import { searchPatient } from '../../../api/chart';
import BasicDatePicker from '../../../components/DatePicker';
import { useSelector } from 'react-redux';
import moment from 'moment';

function Patient () {
    const [patientData, setPatientData] = useState([]);
    const [fname, setFname] = useState();
    const [lname, setLname] = useState();
    const [email, setEmail] = useState();
    const [phone, setPhone] = useState();
    const [chartNumber, setChartNumber] = useState();
    // eslint-disable-next-line no-unused-vars
    const [startDate, setStartDate] = useState(new Date());
    const [transStatus, setTransStatus] = useState(false);
    const d = useSelector(state => state.userInfo.datepicker);
    const dob = moment(d).format('YYYY-MM-DD');
    // const [dob, setDob] = useState(date);

    const condtions = [
        {
            name: 'Firstname',
            selector: (row) =>
                (<Link to={row.chart}>
                    {row.firstname ? row.firstname : '-'}
                </Link>)
        },
        {
            name: 'Lastname',
            selector: (row) => row.lastname ? row.lastname : '-'
        },
        {
            name: 'Date of Birth',
            selector: (row) => row.dob ? row.dob : '-'
        },
        {
            name: 'Phone Number',
            selector: (row) => row.mobilephone ? row.mobilephone : '-'
        },
        {
            name: 'email',
            selector: (row) => (<div className='flex'>{row.email ? row.email : '-'}</div>)
        },
        {
            name: 'chart',
            selector: (row) => (<div className='flex'>{row.chart ? row.chart : '-'}</div>)
        }
        // {
        //     name: 'Actions',
        //     selector: (row) => (<div className='flex'>
        //         {/* <img src={Chat} className='w-[44px] h-[44px] mr-[15px]'/> */}
        //         <img src={Details} className='w-[44px] h-[44px]'/></div>)
        // }
    ];

    const handleSearch = async () => {
        setTransStatus(true);
        if (fname || lname || (dob && dob !== 'Invalid date') || email || phone || chartNumber) {
            const obj = {
                firstname: fname,
                lastname: lname,
                dob: (dob !== 'Invalid date') ? dob : '',
                email,
                mobilephone: phone,
                chartnumber: chartNumber
            };
            const patRes = await searchPatient(obj);
            console.log('searchResult: ', patRes);
            setPatientData(() => [...patRes]);
        } else {
            alert('not a valid search');
        }
        setTransStatus(false);
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSearch();
        }
    };
    const myStyle = {
        display: 'flex',
        height: '100%',
        width: '17%',
        boxShadow: '0px 3px 10px 0px rgba(186, 186, 186, 0.25)',
        borderRadius: '8px',
        paddingLeft: '10px',
        backgroundColor: 'white',
        color: '#414141',
        outline: 'none',
        justifyContent: 'center',
        alignItems: 'center'
    };

    return (
        <div className="m-[40px] z-[0] mt-[20px] select-none">
            <div className="flex justify-between mb-[30px] select-none">
                <label className='text-[#282828] font-["Montserrat"] font-[600] text-[26px] leading-[32px]'>
                    Patient
                </label>
                {/* <span className='text-primary font-["Montserrat"] font-[600] text-[14px] leading-[17px] flex items-center h-[25px]'>
                    <span className="text-[22px] pr-[5px]">+</span>Invite new user
                </span> */}
            </div>

            <div className="w-full h-[50px] flex relative mb-[15px] gap-x-2 select-none">

                <TextField
                    style={
                        'h-full w-[17%] shadow-[0px_3px_10px_0px_rgba(186,186,186,0.25)] rounded-[8px] pl-[10px] text-[#414141] outline-none'
                    }
                    placeholder={'Firstname'}
                    name={'firstname'}
                    id={'firstname'}
                    type={'text'}
                    value={fname}
                    onChange={(event) => setFname(event.target.value)}
                    onKeyDown={handleKeyPress}
                />
                <TextField
                    style={
                        'h-full w-[17%] shadow-[0px_3px_10px_0px_rgba(186,186,186,0.25)] rounded-[8px]  pl-[10px] text-[#414141] outline-none'
                    }
                    placeholder={'Lastname'}
                    name={'lastname'}
                    id={'lastname'}
                    type={'text'}
                    value={lname}
                    onChange={(event) => setLname(event.target.value)}
                    onKeyDown={handleKeyPress}
                />
                <>
                    <div style={myStyle}>
                        <BasicDatePicker />
                    </div>
                    {/* <TextField
                        style={
                            'h-full w-[17%] shadow-[0px_3px_10px_0px_rgba(186,186,186,0.25)] rounded-[8px]  pl-[10px] text-[#414141] outline-none select-none'
                        }
                        placeholder={'Date of Birth'}
                        name={'dob'}
                        id={'dob'}
                        type={dateInputType}
                        // style={{ color: isFocused ? 'transparent' : 'initial' }}
                        onFocus={(e) => {
                            setDateInputType('date');
                            e.target.style.color='white'
                            window.getSelection().empty()
                        }}
                        onBlur={(e) => {
                            e.target.style.color='black'
                            setDateInputType('text')
                        }}
                        value={dob1}
                        onClick={window.getSelection().empty()}
                        onChange={(event) => {
                            console.log('setting date change: ', event.target.value);
                            setDateInputType('text');
                            date_formatter(event.target.value)
                            setDob(event.target.value, 'yyyy-MM-dd');
                            event.target.blur()
                        }}
                    >
                        {format(startDate, 'yyyy-MM-dd')}
                    </TextField> */}
                </>
                <TextField
                    style={
                        'h-full w-[17%] shadow-[0px_3px_10px_0px_rgba(186,186,186,0.25)] rounded-[8px]  pl-[10px] text-[#414141] outline-none'
                    }
                    placeholder={'Email'}
                    name={'email'}
                    id={'email'}
                    type={'email'}
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                    onKeyDown={handleKeyPress}
                />
                <TextField
                    style={
                        'h-full w-[17%] shadow-[0px_3px_10px_0px_rgba(186,186,186,0.25)] rounded-[8px]  pl-[10px] text-[#414141] outline-none'
                    }
                    placeholder={'Phone number'}
                    name={'phone'}
                    id={'phone'}
                    type={'number'}
                    value={phone}
                    onChange={(event) => setPhone(event.target.value)}
                    onKeyDown={handleKeyPress}
                />
                <TextField
                    style={
                        'h-full w-[17%] shadow-[0px_3px_10px_0px_rgba(186,186,186,0.25)] rounded-[8px] pl-[10px] text-[#414141] outline-none'
                    }
                    placeholder={'Chartnumber'}
                    name={'Chartnumber'}
                    id={'Chartnumber'}
                    type={'number'}
                    value={chartNumber}
                    onChange={(event) => setChartNumber(event.target.value)}
                    onKeyDown={handleKeyPress}
                />
                <div className="h-full w-[10%] shadow-[0px_3px_10px_0px_rgba(186,186,186,0.25)] text-[#414141] relative">
                    <Button
                        style={{
                            height: '50px',
                            width: '100%',
                            background: 'rgb(0, 121, 188)',
                            borderRadius: '0px 8px 8px 0px'
                            // outline: 'none',
                            // boxShadow: 'none',
                            // border: 'none',

                        }}
                        startIcon={transStatus ? <CircularProgress color='inherit' size={20} /> : <img color='white' src={Search} />}
                        variant='contained'
                        title='Search'
                        onClick={handleSearch}
                        disabled={transStatus}
                    >{'Search'}</Button>
                </div>
            </div>
            <div
                className={
                    'w-full bg-[#ffffff] shadow-[0px_3px_10px_0px_rgba(186,186,186,0.25)] rounded-[10px] px-[20px] font-["Montserrat"] h-full relative flex flex-col'
                }
            >
                {console.log('patchange: ', patientData)}
                <Table allData={patientData} values={condtions} />
            </div>
        </div>
    );
}

export default Patient;
