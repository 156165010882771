/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import Attach from '../../../assets/attach.svg';
import Send from '../../../assets/send.svg';
import TextField from '../../../components/TextField';

import React, { useState, useEffect, useReducer, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { listMessagesForRoom as ListMessages } from '../../../graphql/queries';
import { createMessage as CreateMessage } from '../../../graphql/mutations';
import { onCreateMessageByRoomId } from '../../../graphql/subscriptions';
import { useMutation, useQuery } from '@apollo/client';

const CREATE_MESSAGE = 'CREATE_MESSAGE';
const SET_MESSAGES = 'SET_MESSAGES';
const SET_LOADING = 'SET_LOADING';

const initialState = {
    messages: [],
    loading: true
};

function reducer (state, action) {
    switch (action.type) {
    case CREATE_MESSAGE:
        return {
            ...state, messages: [...state.messages, action.message]
        };
    case SET_MESSAGES:
        return {
            ...state, messages: action.messages, loading: false
        };
    case SET_LOADING:
        return {
            ...state, loading: action.loading
        };
    default:
        return state;
    }
}

const scrollToRef = (ref) => {
    if (!ref.current) return;
    window.scrollTo(0, ref.current.offsetTop);
};

const scrollToRefWithAnimation = ref => {
    if (!ref.current) return;
    window.scrollTo({
        top: ref.current.offsetTop,
        behavior: 'smooth'
    });
};

function chatPage () {
    const { userInfo } = useSelector(state => state.userInfo);

    const formikState = useFormik({
        initialValues: {
            message: ''
        },
        onSubmit: async (values) => {
            if (!values.message) return;
            const message = { owner: user, content: values.message, roomId: id };
            dispatch({ type: CREATE_MESSAGE, message });
            setTimeout(() => {
                executeScrollWithAnimation();
            });
            createMessage({ variables: { input: message } });
            empty();
        },
        enableReinitialize: true
    });

    const empty = () => {
        formikState.setFieldValue('message', '');
    };
    const [state, dispatch] = useReducer(reducer, initialState);
    const [user, setUser] = useState(userInfo.uname);
    const params = useParams();
    const scrollRef = useRef(null);
    const executeScroll = () => scrollToRef(scrollRef);
    const executeScrollWithAnimation = () => scrollToRefWithAnimation(scrollRef);
    const { name, id } = params;
    let isMounted = true;
    const { loading, error, data } = useQuery(ListMessages,
        {
            variables: {
                roomId: id,
                sortDirection: 'ASC'
            }
        });
    const [createMessage, { loading1, error1 }] = useMutation(CreateMessage);

    useEffect(() => {
        if (data) {
            return dispatch({ type: SET_MESSAGES, messages: data.listMessagesForRoom.items });
        }
        setUserState();
        subscribe();
        return () => {
            isMounted = false;
        };
    }, [data]);

    let socket;
    let i = 1;

    function subscribe () {
        const endpoint = 'wss://qesrkljau5guffvdwkojozp7qe.appsync-realtime-api.us-east-2.amazonaws.com/graphql';
        const accessToken = JSON.parse(localStorage.getItem('userInfo')).auth.AuthenticationResult.AccessToken;
        const authHeader = btoa(JSON.stringify({ Authorization: accessToken, host: 'qesrkljau5guffvdwkojozp7qe.appsync-api.us-east-2.amazonaws.com' }));

        socket = new WebSocket(`${endpoint}?header=${authHeader}&payload=e30=`, ['graphql-ws']);

        socket.addEventListener('open', (event) => {
            socket.send(JSON.stringify({
                id: (i++).toString(),
                type: 'start',
                payload: {
                    data: JSON.stringify({
                        query: onCreateMessageByRoomId,
                        variables: {
                            roomId: id
                        }
                    }),
                    extensions: {
                        authorization: {
                            Authorization: JSON.parse(localStorage.getItem('userInfo')).auth.AuthenticationResult.AccessToken,
                            host: 'qesrkljau5guffvdwkojozp7qe.appsync-api.us-east-2.amazonaws.com'
                        }
                    }
                }
            }));
        });

        socket.addEventListener('message', (event) => {
            if (JSON.parse(event.data).type === 'data') {
                const onCreateMessageByRoomId = JSON.parse(event.data).payload.data.onCreateMessageByRoomId;
                if (onCreateMessageByRoomId.owner === user) return;
                dispatch({ type: CREATE_MESSAGE, message: onCreateMessageByRoomId });
                executeScrollWithAnimation();
            }
        });
    }

    async function setUserState () {
        if (!isMounted) return;
        setUser(userInfo.uname);
    }

    return (
        <div className='w-[calc(100%-450px)] h-full bg-[#F9FAFD]'>
            <div style={{ height: '62px' }} className='flex h-[62px] px-[20px] bg-[#FFFFFF] border-b-[2px] justify-between'>
                <div className='flex items-center'>
                    <div className='w-[44px] h-[44px] font-[600] font-["Montserrat"] text-[14px] leading-[17px] text-[#FFFFFF] bg-primary rounded-full flex justify-center items-center'>
                        {/* {chatTitle?.split(' ').map((name) => name[0])} */}{name}
                    </div>
                    <div className="ml-[18px] flex flex-col justify-between">
                        {/* {chatTitle || ''} */}{name}
                        <span className='text-[#818181] text-[12px]'>#123456</span>
                    </div>
                </div>
                <div className='flex items-center font-[600] font-["Montserrat"] text-[14px] text-primary'>select</div>
            </div>
            <div style={{ height: 'calc(100% - 80px)' }} className='h-[calc(100%-60px)] flex justify-center items-center relative'>
                <div className='w-full h-full overflow-y-scroll overflow-x-hidden pb-[90px] flex flex-col justify-end'>
                    {
                        state.messages.map((message, index) => (
                            <div
                                ref={(index === Number(state.messages.length - 1) ? scrollRef : null)}
                                key={message.id || message.content}
                                // style={messageContainerStyle(user, message)}
                            >
                                {(user && user === message.owner)
                                    ? <div className='w-(calc(100%-200px)) mx-[20px] my-[12.5px] flex justify-end'>
                                        <div className='text-[#FFFFFF] font-[400] text-[14px] font-["Montserrat"] rounded-r-[22px] rounded-tl-[22px] rounded-tb-[10px] shadow-[0px_3px_12px_0px_rgba(183,183,183,0.15)] p-[10px] max-w-[400px] bg-primary inline-block'><div className='w-full flex justify-between  '><div className='mr-[20px] max-w-[300px]'>{message.owner + ' : ' + message.content}</div><div className='w-[60px] flex flex-col justify-end'></div></div></div>
                                    </div>
                                    : <div className='w-(calc(100%-200px)) mx-[20px] my-[12.5px] flex justify-start'>
                                        <div className='text-[#414141] font-[400] text-[14px] font-["Montserrat"] rounded-l-[22px] rounded-tr-[22px] rounded-tb-[10px] shadow-[0px_3px_12px_0px_rgba(183,183,183,0.15)] p-[10px] max-w-[400px] bg-[#ffffff] inline-block'><div className='w-full flex justify-between  '><div className='mr-[20px] max-w-[300px]'>{message.owner + ' : ' + message.content}</div><div className='w-[60px] flex flex-col justify-end'></div></div></div>
                                    </div>}
                                {/* <p>{message.owner}</p> */}
                            </div>
                        ))
                    }
                </div>
                <form className={'absolute w-[calc(100%-40px)] px-[20px] py-[10px] bottom-[0px] flex justify-between bg-[#F9FAFD]'} onSubmit={formikState.handleSubmit} autoComplete='off'>
                    <div className='relative w-[calc(100%-60px)] shadow-[0px_3px_12px_0px_rgba(183,183,183,0.15)] bg-[#ffffff] rounded-[8px] h-[50px] flex items-center'>
                        <div className="w-[20px] h-full absolute top-0 left-0 flex items-center ml-[10px]">
                            <img src={Attach} className="w-[20px] h-[20px]" />
                        </div>
                        <TextField
                            style={'w-full h-full pl-[50px] text-[#414141] outline-none rounded-[8px]'}
                            placeholder={'Message'}
                            onChange={formikState.handleChange}
                            name='message'
                            value={formikState.values.message}
                        />
                    </div>
                    <button type='submit' className='w-[50px] h-[50px] bg-[#ffffff] rounded-[8px] shadow-[0px_3px_12px_0px_rgba(183,183,183,0.15)] flex items-center justify-center'><img src={Send} className={'w-[27px] h-[27px]'}/></button>
                </form>
            </div>
        </div>
    );
}

export default chatPage;
