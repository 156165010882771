/* eslint-disable indent */
/* eslint-disable space-before-function-paren */
/* eslint-disable no-console */
// import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import PatientOn from '../../assets/patientOn.svg';
import PatientOff from '../../assets/patientOff.svg';
import AppointmentsOff from '../../assets/appointmentsOff.svg';
import AppointmentsOn from '../../assets/appointmentsOn.svg';
import ModificationsOff from '../../assets/modification.svg';
import ModificationsOn from '../../assets/modificationOn.svg';
// import FeedbacksOff from '../../assets/feedbacks.svg';
// import { initializeApp } from 'firebase/app';
// import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { useSelector } from 'react-redux';
// import Doc from '../../assets/doc.svg';
// import Doc from '../../assets/profile.svg';
import Logout from '../../assets/logout.svg';
import NotificationSVG from '../../assets/notification.svg';
import ProfileOff from '../../assets/profile.svg';
// import ProfileOn from '../../assets/profileOn.svg';
import Rsi from '../../assets/rsi.png';
import Sen from '../../assets/sen.png';
// import { setNotify } from '../../store/userReducer';
// import { config } from '../../utils/config';
import NotifySide from './NotifySide';
import { config } from '../../utils/config';

function DashBoard() {
    const location = useLocation().pathname;
    const { userInfo } = useSelector((state) => state.userInfo);
    // const dispatch = useDispatch();
    const [NotifyStatus, setNotifyStatus] = useState(false);

    // const firebaseConfig = {
    //     apiKey: 'AIzaSyDsr03zdQtPPr_-Nk8jpMVPTMVzbhgk-fs',
    //     authDomain: 'jit-pushnotification.firebaseapp.com',
    //     projectId: 'jit-pushnotification',
    //     storageBucket: 'jit-pushnotification.appspot.com',
    //     messagingSenderId: '48990956868',
    //     appId: '1:48990956868:web:ce47a78d843abd7b7efb11',
    //     measurementId: 'G-SNNTRMN9VZ'
    // };

    useEffect(() => {
        // Notification.requestPermission().then((permission) => {
        //     if (permission === 'granted') {
        //         const app = initializeApp(firebaseConfig);
        //         const messaging = getMessaging(app);
        //         getToken(messaging, { vapidKey: 'BNenSnvdi5y7msN8jG0cIZlTSv_Gc-IryiECay6iwzrflZnERogyymqP4r3zvngVOwNBuchqRBbebQYldpfUIF8' }
        //         ).then((currentToken) => {
        //             if (currentToken) {
        //                 return axios
        //                     .post(
        //                         `${config.API.LOG}/post-user-token`,
        //                         {
        //                             device_token: currentToken,
        //                             device_type: 'Browser'
        //                         },
        //                         {
        //                             headers: {
        //                                 accesstoken: JSON.parse(localStorage.getItem('userInfo')).auth
        //                                     .AuthenticationResult.AccessToken,
        //                                 Authorization: JSON.parse(localStorage.getItem('userInfo')).auth
        //                                     .AuthenticationResult.IdToken
        //                             }
        //                         }
        //                     )
        //                     .then(res => {
        //                     });
        //                 // setToken(currentToken);
        //             } else {
        //                 console.log('Cannot get token');
        //             }
        //         });
        //         onMessage(messaging, (payload) => {
        //             console.log('Message received.', payload);
        //             console.log('updated notification is: ');
        //             dispatch(setNotify(payload.data));
        //         });
        //         // messaging.onBackgroundMessage((payload) => {
        //         //     console.log('Received background message', payload);
        //         //     PushNotification('success', payload.NotificationSVG.title);
        //         // });
        //     } else {
        //         console.log('Do not have permission');
        //     }
        // });
    }, []);

    return (
        <div className="bg-[#F9FAFD] w-[100vw] h-[100vh] relative z-[2]">
            <div className='max-[1600px]:h-[50px] max-[1600px]:px-[0px] z-[1] flex font-["Montserrat"] font-[600] text-[14px] leading-[17px] text-[#282828] flex-row items-center justify-end py-[10px] px-[20px] gap-[15px] fixed w-full h-[75px] bg-[#ffffff]'>
                <img
                    src={userInfo.hospital.toLowerCase() === 'sen' ? Sen : Rsi}
                    className="max-[1600px]:h-[25px] h-[40px] absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]"
                />
                <div className="flex items-center py-[10px] px-[20px] max-[1600px]:text-[12px]">
                    <img
                        src={ProfileOff}
                        className="max-[1600px]:h-[24px] max-[1600px]:w-[24px] w-[36px] h-[36px] mr-[10px]"
                    />
                    {userInfo.uname}
                    <div
                        className="ml-[40px] max-[1600px]:h-[16px] max-[1600px]:w-[16px] w-[24px] h-[24px] relative"
                        onClick={() => (NotifyStatus ? setNotifyStatus(false) : setNotifyStatus(true))}
                    >
                        <img src={NotificationSVG} className="w-full h-full" />
                        <span className="absolute max-[1600px]:top-[-4px] max-[1600px]:left-[8px] max-[1600px]:h-[10px] max-[1600px]:text-[8px] max-[1600px]:w-[10px] w-[20px] h-[20px] flex justify-center items-center right-[-10px] text-[#ffffff] text-[12px] top-[-10px] bg-[#FF624F] rounded-full">
                            {userInfo.notification.length}
                        </span>
                    </div>
                    {/* <img
                        src={Menu}
                        className="ml-[18px] max-[1600px]:h-[18px] w-[26px] h-[26px]"
                    /> */}
                </div>
            </div>

            <div className="flex flex-row h-full w-full overflow-hidden">
                <div className="w-[143px] max-[1600px]:w-[115px] h-full bg-[#ffffff] shadow-[1px_4px_15px_0_rgba(186,186,186,0.25)] fixed z-[2]">
                    <Link to={'/dashboard/appointments'}>
                        <div className='text-primary max-[1600px]:h-[50px] max-[1600px]:text-[18px] font-["Montserrat"] font-[500] text-[22px] leading-[27px] w-full text-center h-[75px] items-center flex justify-center'>
                            {localStorage.getItem('title')}
                        </div>
                    </Link>
                    <Link to={'appointments'}>
                        <div
                            className={`${location.includes('/dashboard/appointments')
                                ? 'text-primary bg-tab-blue'
                                : 'text-[#818181] bg-[#ffffff]'
                                } font-["Montserrat"] font-[600] max-[1600px]:text-[10px] max-[1600px]:h-[80px] text-[12px] leading-[15px] w-full text-center h-[100px] flex flex-col justify-center`}
                        >
                            <img
                                src={
                                    location.includes('/dashboard/appointments')
                                        ? AppointmentsOn
                                        : AppointmentsOff
                                }
                                className={
                                    'max-[1600px]:h-[20px] w-[27px] h-[27px] self-center mb-[12px]'
                                }
                                alt="user"
                            />
                            Appointments
                        </div>
                    </Link>
                    <Link to={'patient'}>
                        <div
                            className={`${
                                location.includes('/dashboard/patient')
                                    ? 'text-primary bg-tab-blue'
                                    : 'text-[#818181] bg-[#ffffff]'
                            } font-["Montserrat"] font-[600] text-[12px] max-[1600px]:text-[10px] max-[1600px]:h-[80px] leading-[15px] w-full text-center h-[100px] flex flex-col justify-center`}
                        >
                            <img
                                src={location.includes('/dashboard/patient') ? PatientOn : PatientOff}
                                className={'w-[27px] h-[27px] self-center mb-[12px]'}
                                alt="user"
                            />
                            Patients
                        </div>
                    </Link>
                    <Link to={config[config.APPNAME].CLOUD_LOG} target='_blank'>
                        <div
                            className={`${location.includes('/dashboard/logs')
                                ? 'text-primary bg-tab-blue'
                                : 'text-[#818181] bg-[#ffffff]'
                                } font-["Montserrat"] font-[600] text-[12px] max-[1600px]:text-[10px] max-[1600px]:h-[80px] leading-[15px] w-full text-center h-[100px] flex flex-col justify-center`}
                        >
                            <img
                                src={
                                    location.includes('/dashboard/logs')
                                        ? ModificationsOn
                                        : ModificationsOff
                                }
                                className={
                                    'max-[1600px]:h-[20px] w-[27px] h-[27px] self-center mb-[12px]'
                                }
                                alt="user"
                            />
                            Logs
                        </div>
                    </Link>
                    {/* <Link to={'profile'}>
                        <div
                            className={`${location.includes('/dashboard/profile')
                                ? 'text-primary bg-tab-blue'
                                : 'text-[#818181] bg-[#ffffff]'
                                } font-["Montserrat"] font-[600] max-[1600px]:text-[10px] max-[1600px]:h-[80px] text-[12px] leading-[15px] w-full text-center h-[100px] flex flex-col justify-center`}
                        >
                            <img
                                src={
                                    location.includes('/dashboard/profile')
                                        ? ProfileOn
                                        : ProfileOff
                                }
                                className={
                                    'max-[1600px]:h-[20px] w-[27px] h-[27px] self-center mb-[12px]'
                                }
                                alt="user"
                            />
                            My Profile
                        </div>
                    </Link> */}
                    <Link to={'/'}>
                        <div
                            className='text-[#818181] max-[1600px]:text-[10px] max-[1600px]:h-[80px] absolute bottom-[20px] bg-[#ffffff] font-["Montserrat"] font-[600] text-[12px] leading-[15px] w-full text-center h-[100px] flex flex-col justify-center'
                            onClick={() => {
                                localStorage.setItem(
                                    'userInfo',
                                    JSON.stringify({ type: '', auth: '' })
                                );
                            }}
                        >
                            <img
                                src={Logout}
                                className={
                                    'max-[1600px]:h-[18px] w-[27px] h-[27px] self-center mb-[12px]'
                                }
                                alt="user"
                            />
                            Log out
                        </div>
                    </Link>
                </div>

                <div className="ml-[143px] mt-[75px] max-[1600px]:mt-[50px] max-[1600px]:ml-[115px] w-[calc(100%-143px)] max-[1600px]:h-[calc(100%-50px)]  max-[1600px]:w-[calc(100%-115px)] overflow-y-scroll">
                    <Outlet />
                </div>
            </div>
            {NotifyStatus && <NotifySide setNotify={(data) => setNotifyStatus(data)} />}
        </div>
    );
}

export default DashBoard;
