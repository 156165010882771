import React from 'react';
import { Route } from 'react-router-dom';
// import Splash from '../pages/onBoarding/Splash';
// import Onboarding from '../pages/onBoarding/Onboarding';
import SignIn from '../pages/signIn';
// import ForgotPassword from '../pages/signIn/forgotPassword';
// import ResetPassword from '../pages/signIn/resetPassword';
import Register from '../pages/onBoarding/Register';
import ULToken from '../pages/onBoarding/ULToken';
// import ULToken from '../pages/onBoarding/ULToken';

const OnboardingRoutes = (
    <>
        {/* <Route path="" element={<Splash/>}/>
        <Route path="sign" element={<Onboarding/>}/> */}
        <Route path="" element={<SignIn/>}/>
        {/* <Route path="token/*" element={<ULToken />}/> */}
        {/* <Route path="forgot" element={<ForgotPassword/>}/> */}
        {/* <Route path="reset" element={<ResetPassword/>}/> */}
        <Route path="register" element={<Register/>}/>
        <Route path="token/:hash" element={<ULToken/>}/>
    </>
);

export default OnboardingRoutes;
