import { toast } from 'react-toastify';

const PushNotification = (type, title, duration = 5000) => {
    switch (type) {
    case 'success': return toast.success(title, {
        position: 'top-center',
        autoClose: duration,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored'
    });

    case 'error': return toast.error(title, {
        position: 'top-center',
        autoClose: duration,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored'
    });

    case 'warning': return toast.warning(title, {
        position: 'top-center',
        autoClose: duration,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored'
    });

    default:
        toast.info(title, {
            position: 'top-center',
            autoClose: duration,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored'
        });
    };
};

export default PushNotification;
