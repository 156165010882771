import { createSlice } from '@reduxjs/toolkit';
import { format } from 'date-fns';

const initialState = {
    isLoggedIn: false,
    datepicker: '',
    userInfo: {
        email: '',
        uname: '',
        hospital: '',
        id: '',
        chatid: '',
        title: '',
        date: format(new Date(), 'yyyy/MM/dd'),
        doc: '6',
        loc: '0',
        type: '0',
        dates: format(new Date(), 'yyyy-MM-dd'),
        loader: false,
        apptdw: 'day',
        notification: []
    }
};

const UserSlice = createSlice({
    name: 'userInfoSlice',
    initialState,
    reducers: {
        setUserName: (state, action) => {
            state.userInfo.uname = action.payload;
        },
        setEmail: (state, action) => {
            state.userInfo.email = action.payload;
        },
        setHospital: (state, action) => {
            state.userInfo.hospital = action.payload;
        },
        setUserID: (state, action) => {
            state.userInfo.id = action.payload;
        },
        setChatID: (state, action) => {
            state.userInfo.chatid = action.payload;
        },
        setTitle: (state, action) => {
            state.userInfo.title = action.payload;
        },
        setDate: (state, action) => {
            state.userInfo.date = action.payload;
        },
        setDoc: (state, action) => {
            state.userInfo.doc = action.payload;
        },
        setLoc: (state, action) => {
            state.userInfo.loc = action.payload;
        },
        setType: (state, action) => {
            state.userInfo.type = action.payload;
        },
        setDates: (state, action) => {
            state.userInfo.dates = action.payload;
        },
        setLoader: (state, action) => {
            state.userInfo.loader = action.payload;
        },
        setApptDW: (state, action) => {
            state.userInfo.apptdw = action.payload;
        },
        setNotify: (state, action) => {
            state.userInfo.notification = [action.payload, ...state.userInfo.notification];
        },
        setDatePicker: (state, action) => {
            state.datepicker = action.payload;
        }
    }
});

export const { setUserName, setHospital, setUserID, setChatID, setTitle, setDate, setDoc, setLoc, setType, setDates, setEmail, setLoader, setApptDW, setNotify, setDatePicker } = UserSlice.actions;

export default UserSlice.reducer;
