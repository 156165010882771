/* eslint-disable no-console */
import React, { useRef } from 'react';
import lt from '../../assets/dlt.svg';
import gt from '../../assets/dgt.svg';
import Cal from '../../assets/calendar.svg';
import 'react-datepicker/dist/react-datepicker.css';

function Table ({ allData, values, filter, date, ...props }) {
    const parentRef = useRef(null);
    const firstChildRef = useRef(null);
    const secondChildRef = useRef(null);
    // useEffect(() => {
    //     const parentHeight = parentRef.current.offsetHeight;
    //     const firstChildHeight = firstChildRef.current.offsetHeight;
    //     const remainingHeight = parentHeight - firstChildHeight;
    //     secondChildRef.current.style.maxHeight = `${remainingHeight - 20}px`;
    // }, []);
    return (
        <>
            {date && (
                <div className="flex justify-between items-center">
                    <div className='flex bg-[#ffffff] py-[25px] font-["Montserrat"] text-[16px] font-[600] text-primary'>
                        <img src={lt} className="mr-[10px]" />
            18 May, 2022
                        <img src={Cal} className={'ml-[10px]'} />
                        <img src={gt} className="ml-[10px]" />
                    </div>
                    <select className='border-[1px] border-[#E0E0E0] flex w-[150px] h-[50px] justify-center px-[5px] rounded-[8px] outline-none text-[#818181] text-[14px] font-[600] font-["Montserrat"]'>
                        <option>Day</option>
                        <option>Week</option>
                    </select>
                </div>
            )}
            <div
                className={
                    'w-full h-full bg-[#ffffff] rounded-[10px] flex flex-col flex-grow'
                }
                ref={parentRef}
            >
                <div
                    className={'w-full h-[67px] max-[1600px]:h-[50px]'}
                    ref={firstChildRef}
                >
                    <div
                        className={`w-full max-[1600px]:h-[40px] grid ${
                            values?.length === 10
                                ? 'grid-cols-10'
                                : values?.length === 9
                                    ? 'grid-cols-9'
                                    : values?.length === 8
                                        ? 'grid-cols-8'
                                        : values?.length === 7
                                            ? 'grid-cols-7'
                                            : values?.length === 6
                                                ? 'grid-cols-6'
                                                : values?.length === 5
                                                    ? 'grid-cols-5'
                                                    : values?.length === 4
                                                        ? 'grid-cols-4'
                                                        : 'grid-cols-3'
                        } grid-flow-row items-center h-[67px] border-b-[1px] pl-[25px]`}
                    >
                        {values.map((headings, index) => (
                            <div
                                key={index}
                                className={
                                    'text-[#818181] text-[14px] font-[600] max-[1600px]:text-[11px] max-[1600px]:font-[500] leading-[17px]'
                                }
                            >
                                {headings.name}
                            </div>
                        ))}
                    </div>
                </div>
                <div className="w-full overflow-y-auto flex-grow" ref={secondChildRef}>
                    {allData?.length
                        ? (
                            allData.map((row, rindex) => (
                                filter
                                    ? (filter !== '' && ([row?.pat_name, (row?.patient_fname + ' ' + row?.patient_lname), row?.chart_number, row?.patient_chart_number, row?.phy_name, row?.action_description?.doctor_name, row?.appt_reason, row?.action_description?.appointment_location, row?.action_description?.appointment_purpose, row?.action_description?.appt_reason, row?.action_description?.medication_text, (row?.action_description?.physician_fname + ' ' + row?.action_description?.physician_lname), row?.action_description?.name, row?.action_description?.location_name, row?.action_description?.reason_for_cancelation].some(element => element?.toString().toLowerCase().includes(filter.toLowerCase())))
                                        ? <div
                                            key={rindex}
                                            className={`w-full grid ${
                                                values?.length === 10
                                                    ? 'grid-cols-10'
                                                    : values?.length === 9
                                                        ? 'grid-cols-9'
                                                        : values?.length === 8
                                                            ? 'grid-cols-8'
                                                            : values?.length === 7
                                                                ? 'grid-cols-7'
                                                                : values?.length === 6
                                                                    ? 'grid-cols-6'
                                                                    : values?.length === 5
                                                                        ? 'grid-cols-5'
                                                                        : values?.length === 4
                                                                            ? 'grid-cols-4'
                                                                            : 'grid-cols-3'
                                            } grid-flow-row items-center max-[1600px]:h-[40px] h-[84px] border-b-[1px] pl-[25px]`}
                                        >
                                            {values.map((column, index) => (
                                                <div
                                                    key={index}
                                                    className='font-["Montserrat"] max-[1600px]:text-[10px] text-[14px] font-[400] text-[#414141] h-full w-full flex items-center'
                                                >
                                                    {column.selector(row, rindex)}
                                                </div>
                                            ))}
                                        </div>
                                        : null)
                                    : <div
                                        key={rindex}
                                        className={`w-full grid ${
                                            values?.length === 10
                                                ? 'grid-cols-10'
                                                : values?.length === 9
                                                    ? 'grid-cols-9'
                                                    : values?.length === 8
                                                        ? 'grid-cols-8'
                                                        : values?.length === 7
                                                            ? 'grid-cols-7'
                                                            : values?.length === 6
                                                                ? 'grid-cols-6'
                                                                : values?.length === 5
                                                                    ? 'grid-cols-5'
                                                                    : values?.length === 4
                                                                        ? 'grid-cols-4'
                                                                        : 'grid-cols-3'
                                        } grid-flow-row items-center max-[1600px]:h-[75px] h-[75px] border-b-[1px] pl-[25px]`}
                                    >
                                        {values.map((column, index) => (
                                            <div
                                                key={index}
                                                className='font-["Montserrat"] max-[1600px]:text-[10px] text-[14px] font-[400] text-[#414141] h-full w-full flex items-center '
                                            >
                                                {column.selector(row, rindex)}
                                            </div>
                                        ))}
                                    </div>

                            ))
                        )
                        : (
                            <div className='w-full flex justify-center items-center max-[1600px]:h-[60px] h-[84px] font-["Montserrat"] text-[14px] font-[400] text-primary'>
              No data
                            </div>
                        )}
                </div>
            </div>
        </>
    );
}

export default Table;
