import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import DatePicker from 'react-datepicker';
import { setDatePicker } from '../../store/userReducer';
export default function BasicDatePicker () {
    const dispatch = useDispatch();
    const [date, setDate] = useState();
    return (
        <div>
            <DatePicker selected={date} onChange={(date) => {
                dispatch(setDatePicker(date));
                setDate(date);
            }} placeholderText="Date of Birth" dateFormat="MMM dd, yyyy" />
        </div>
    );
}
