/* eslint-disable no-lone-blocks */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React, { useState, useEffect, useReducer } from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import Plus from '../../../assets/plus.svg';
import X from '../../../assets/x.svg';

import { listRooms } from '../../../graphql/queries';
import { createRoom as CreateRoom } from '../../../graphql/mutations';
import { onCreateRoom as OnCreateRoom } from '../../../graphql/subscriptions';
import TextField from '../../../components/TextField';

import Search from '../../../assets/search.svg';
import { useSelector } from 'react-redux';
import { useMutation, useQuery } from '@apollo/client';

const CREATE_ROOM = 'CREATE_ROOM';
const SET_ROOMS = 'SET_ROOMS';
const SET_LOADING = 'SET_LOADING';

const initialState = {
    loading: false,
    rooms: []
};

function reducer (state, action) {
    switch (action.type) {
    case CREATE_ROOM:
        return { ...state, rooms: [...state.rooms, action.room] };
    case SET_ROOMS:
        return { ...state, rooms: action.rooms };
    case SET_LOADING:
        return { ...state, loading: action.loading };
    default:
        return state;
    }
}

export default function Chat () {
    const { userInfo } = useSelector(state => state.userInfo);
    const [User, setUser] = useState(false);
    const [name, setname] = useState('');
    const navigate = useNavigate();

    const { loading, error, data } = useQuery(listRooms, { variables: { limit: 1000, owner: userInfo.email } });
    const [createRoom, { loading1, error1 }] = useMutation(CreateRoom);
    const [state, dispatch] = useReducer(reducer, initialState);
    const [Move, setMove] = useState(true);
    useEffect(() => {
        if (data) {
            return dispatch({ type: SET_ROOMS, rooms: data.listRooms.items });
        }
        subscribe();
        return () => {
            // socket.close();
        };
    }, [data]);

    let socket;
    let i = 1;

    function subscribe () {
        const endpoint = 'wss://qesrkljau5guffvdwkojozp7qe.appsync-realtime-api.us-east-2.amazonaws.com/graphql';
        const accessToken = JSON.parse(localStorage.getItem('userInfo')).auth.AuthenticationResult.AccessToken;
        const authHeader = btoa(JSON.stringify({ Authorization: accessToken, host: 'qesrkljau5guffvdwkojozp7qe.appsync-api.us-east-2.amazonaws.com' }));

        socket = new WebSocket(`${endpoint}?header=${authHeader}&payload=e30=`, ['graphql-ws']);

        socket.addEventListener('open', (event) => {
            socket.send(JSON.stringify({
                id: (i++).toString(),
                type: 'start',
                payload: {
                    data: JSON.stringify({
                        query: OnCreateRoom
                    }),
                    extensions: {
                        authorization: {
                            Authorization: JSON.parse(localStorage.getItem('userInfo')).auth.AuthenticationResult.AccessToken,
                            host: 'qesrkljau5guffvdwkojozp7qe.appsync-api.us-east-2.amazonaws.com'
                        }
                    }
                }
            }));
        });

        socket.addEventListener('message', (event) => {
            if (JSON.parse(event.data).type === 'data') {
                const onCreateroom = JSON.parse(event.data).payload.data.onCreateRoom;
                dispatch({ type: CREATE_ROOM, room: onCreateroom });
                navigate(`chat/${onCreateroom.owners[0] === userInfo.email ? onCreateroom.owners[1] : onCreateroom.owners[0]}/${onCreateroom.id}`);
            }
        });
    }

    const users = [{ email: 'davis.catherine.0003@gmail.com' }, { email: 'n.olan.liam.0001@gmail.com' }, { email: 'gtom25288@gmail.com' }];

    // const message = { owners: [userInfo.email, name], name: userInfo.email };

    return (
        <div className='w-full h-full flex'>
            <div className='w-[450px] bg-[#FFFFFF] h-full'>
                <div style={{ height: '60px' }} className='w-full flex items-center h-[64px]'>
                    <div className={`w-[50%] text-center ${Move ? 'text-primary' : 'text-[#ACACAC]'} font-["Montserrat"] font-[600] text-[14px] duration-[1s]`} onClick={() => setMove(true)}>Patient</div>
                    <div className={`w-[50%] text-center ${Move ? 'text-[#ACACAC]' : 'text-primary'} font-["Montserrat"] font-[600] text-[14px] duration-[1s]`} onClick={() => setMove(false)}>Clinic</div>
                </div>
                <div className='w-[100%] relative h-[2px] bg-[#E0E0E0]'><div className={`h-[2px] absolute ${Move ? 'translate-x-[0%]' : 'translate-x-[100%]'} w-[50%] bg-primary duration-[1s]`}></div></div>
                <div className="w-full h-[60px] flex relative justify-between border-b-[1px]">
                    <div className="w-[20px] h-full absolute top-0 left-0 flex items-center ml-[10px]">
                        <img src={Search} className="w-[20px] h-[20px]" />
                    </div>
                    <TextField
                        style={'h-full w-full pl-[50px] text-[#414141] outline-none'}
                        placeholder={'Search'}
                    />
                </div>
                {
                    data && state.rooms.map((room) =>
                        room.owners.includes(userInfo.email) && (
                            <Link to={`chat/${room.owners[0] === userInfo.email ? room.owners[1] : room.owners[0]}/${room.id}`} key={room.id}>
                                <div className='flex justify-between border-b-[1px]'>
                                    <div className='flex p-[15px] bg-[#FFFFFF]'>
                                        <div className='min-w-[44px] h-[44px] font-[600] font-["Montserrat"] text-[14px] leading-[17px] text-[#FFFFFF] bg-primary rounded-full flex justify-center items-center'>
                                            {/* {(data.title.split(' ')).slice(0, 2).map((name) => name[0])} */}
                                        </div>
                                        <div className="ml-[18px] flex flex-col justify-around">
                                            <div>{room.owners[0] === userInfo.email ? room.owners[1] : room.owners[0]}</div>
                                            {/* {data.message && <div className='text-[#818181] text-[12px]'>{data.message?.text}</div>} */}
                                        </div>
                                    </div>
                                    {/* <div className='font-[500] font-["Montserrat"] text-[12px] text-[#818181] p-[15px]'>{data?.date_update.split('T')[1]}</div> */}
                                </div>
                            </Link>
                        ))
                }
                <button
                    className={'flex items-center text-[15px] font-[600] text-primary justify-center w-[100%] h-[50px] bg-[#e3e3e3]'}
                    onClick={() => {
                        // const hasOwner = state.rooms.some(room => room.owners.includes(userInfo.email));
                        // if (!hasOwner) {
                        //     createRoom({ variables: { input: message } });
                        // }
                        setUser(true);
                    }
                    }><img src={Plus} className={'w-[15px] h-[15px] mr-[15px]'}/>Message</button>
            </div>
            <Outlet/>
            {User && <div className='transition w-[calc(100%-143px)] h-[calc(100%-75px)] flex justify-center absolute bottom-0 right-0 bg-[rgba(0,0,0,0.2)] duration-[2s] overflow-y-auto mt-[75px] z-[101]'>
                <div className='w-[600px] flex flex-col justify-between'>
                    <div className='bg-[#ffffff] w-full rounded-[8px] mt-[5%] px-[40px] py-[20px] '>
                        <div className='flex justify-between'>
                            <div className='text-[#414141] text-[24px] font-[500]'>Users</div>
                            <img src={X} onClick={() => setUser(false)}/>
                        </div>
                        {users && users.map((user, i) => user.email !== userInfo.email && (
                            <div key={i} className='flex justify-between border-b-[1px] w-full'>
                                <div className='flex p-[15px] w-full' onClick={() => {
                                    const hasOwner = state.rooms.some(room => room.owners.includes(userInfo.email) && room.owners.includes(user.email));
                                    if (!hasOwner) {
                                        createRoom({ variables: { input: { owners: [userInfo.email, user.email], name: userInfo.email } } });
                                        setUser(false);
                                    } else {
                                        const room = state.rooms.find(room => room.owners.includes(userInfo.email) && room.owners.includes(user.email));
                                        navigate(`chat/${room.owners[0] === userInfo.email ? room.owners[1] : room.owners[0]}/${room.id}`);
                                        setUser(false);
                                    }
                                } }>
                                    <div className='min-w-[44px] h-[44px] font-[600] font-["Montserrat"] text-[14px] leading-[17px] text-[#FFFFFF] bg-primary rounded-full flex justify-center items-center'>
                                        {/* {(data.title.split(' ')).slice(0, 2).map((name) => name[0])} */}
                                    </div>
                                    <div className="ml-[18px] flex flex-col justify-around">
                                        {user.email}
                                        {/* <div>{room.owners[0] === userInfo.email ? room.owners[1] : room.owners[0]}</div> */}
                                        {/* {data.message && <div className='text-[#818181] text-[12px]'>{data.message?.text}</div>} */}
                                    </div>
                                </div>
                                {/* <div className='font-[500] font-["Montserrat"] text-[12px] text-[#818181] p-[15px]'>{data?.date_update.split('T')[1]}</div> */}
                            </div>)
                        )
                        }
                    </div>
                </div>
            </div>
            }
        </div>
    );
}

{ /* <div className='w-[600px] flex flex-col flex-shrink'>
                    <div className='flex justify-between'>
                        <div className='text-[#414141] text-[24px] font-[500]'>User</div>
                        <img src={X} onClick={() => setUser(false)}/>
                    </div>
                    <div className='flex justify-between border-b-[1px]'>
                        <div className='flex p-[15px] bg-[#FFFFFF]'>
                            <div className='min-w-[44px] h-[44px] font-[600] font-["Montserrat"] text-[14px] leading-[17px] text-[#FFFFFF] bg-primary rounded-full flex justify-center items-center'>
                                {/* {(data.title.split(' ')).slice(0, 2).map((name) => name[0])}
                            </div>
                            <div className="ml-[18px] flex flex-col justify-around">
                            davis.catherine.0003@gmail.com
                                {/* <div>{room.owners[0] === userInfo.email ? room.owners[1] : room.owners[0]}</div> */ }
{ /* {data.message && <div className='text-[#818181] text-[12px]'>{data.message?.text}</div>}
                            </div>
                        </div>
                        {/* <div className='font-[500] font-["Montserrat"] text-[12px] text-[#818181] p-[15px]'>{data?.date_update.split('T')[1]}</div>
                    </div>
                    <div className='flex justify-between border-b-[1px]'>
                        <div className='flex p-[15px] bg-[#FFFFFF]'>
                            <div className='min-w-[44px] h-[44px] font-[600] font-["Montserrat"] text-[14px] leading-[17px] text-[#FFFFFF] bg-primary rounded-full flex justify-center items-center'>
                                {/* {(data.title.split(' ')).slice(0, 2).map((name) => name[0])}
                            </div>
                            <div className="ml-[18px] flex flex-col justify-around">
                            davis.catherine.0003@gmail.com
                                {/* <div>{room.owners[0] === userInfo.email ? room.owners[1] : room.owners[0]}</div> */ }
{ /* {data.message && <div className='text-[#818181] text-[12px]'>{data.message?.text}</div>}
                            </div>
                        </div>
                        {/* <div className='font-[500] font-["Montserrat"] text-[12px] text-[#818181] p-[15px]'>{data?.date_update.split('T')[1]}</div>
                    </div>
                </div> */ }
