import React from 'react';
import { Route } from 'react-router-dom';
import DashBoard from '../pages/dashBoard';
import Appointment from '../pages/dashBoard/appointment';
import Patient from '../pages/dashBoard/patient';
import PatientChart from '../pages/dashBoard/patient/PatientChart';
// import Chat from '../pages/dashBoard/emptyChat';
// import ChatPage from '../pages/dashBoard/emptyChat/Chat';
import Profile from '../pages/dashBoard/profile';
// import ForgotPassword from '../pages/dashBoard/profile/forgotPassword';
import Terms from '../pages/dashBoard/profile/terms';
import Privacy from '../pages/dashBoard/profile/privacy';
import Modification from '../pages/dashBoard/modification';
import History from '../pages/dashBoard/modification/History';
import HistoryOut from '../pages/dashBoard/modification/HistoryOut';
import Notification from '../pages/dashBoard/notification';
import Chat from '../pages/dashBoard/chat';
import ChatPage from '../pages/dashBoard/chat/chatPage';
// import Log from '../pages/dashBoard/log';
// import CogChatPage from '../pages/dashBoard/emptyChat/CogChat';

const DashBoardRoutes = (
    <>
        <Route path="dashboard" element={<DashBoard/>}>
            <Route path="notification" element={<Notification/>}/>
            <Route path="appointments" element={<Appointment/>}/>
            <Route path="patient" element={<Patient/>}/>
            <Route path="patient/:id" element={<PatientChart/>}/>
            {/* <Route path="logs" element={<Log/>}/> */}
            <Route path="appointments/chart" element={<PatientChart/>}/>
            <Route path="modifications" element={<Modification/>}/>
            <Route path="modifications/history" element={<History/>}>
                <Route path="hist" element={<HistoryOut/>}/>
            </Route>
            <Route path="chat" element={<Chat/>}>
                <Route path="chat/:name/:id" element={<ChatPage/>}/>
            </Route>
            <Route path="feedbacks"/>
            <Route path="profile" element={<Profile/>}>
                {/* <Route path="reset" element={<ForgotPassword/>}/> */}
                <Route path="terms" element={<Terms/>}/>
                <Route path="privacy" element={<Privacy/>}/>
            </Route>
        </Route>
    </>
);

export default DashBoardRoutes;
